import React from "react";
import LoanComp from "../components/grants/Loan";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";

const Loan = () => {
  return (
    <>
      <LoanComp />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default Loan;
