import { DataGrid } from "@mui/x-data-grid";
import {
  budgetColumns,
  projectColumns,
  suggestionColumns,
  reportColumns,
} from "../../datatablesource";
import { BiSearch, BiArrowToRight } from "react-icons/bi";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userRequest } from "../../requestMethod";
import styled from "styled-components";

const DataTable = styled.div`
  height: 100%;
  padding: 2rem 20px;
  margin: 2rem auto;

  .datatableTitle {
    width: 100%;
    font-size: 24px;
    color: gray;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .link {
      text-decoration: none;
      color: green;
      font-size: 16px;
      font-weight: 400;
      border: 1px solid green;
      padding: 5px;
      border-radius: 5px;
      cursor: pointer;
    }
  }

  .tableHeader {
    font-weight: 600;
    color: teal;
  }

  th {
    font-weight: 600;
  }

  .cellWithImg {
    display: flex;
    align-items: center;

    .cellImg {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }

  .cellWithStatus {
    padding: 5px;
    border-radius: 5px;

    &.active {
      background-color: rgba(0, 128, 0, 0.05);
      color: green;
    }
    &.pending {
      background-color: rgba(255, 217, 0, 0.05);
      color: goldenrod;
    }
    &.passive {
      background-color: rgba(255, 0, 0, 0.05);
      color: crimson;
    }
  }

  .cellAction {
    display: flex;
    align-items: center;
    gap: 15px;

    .viewButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: darkblue;
      border: 1px dotted rgba(0, 0, 139, 0.596);
      cursor: pointer;
    }

    .unsubscribeButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: rgb(148, 148, 7);
      border: 1px dotted rgba(148, 148, 20, 0.596);
      cursor: pointer;
    }

    .subscribeButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: green;
      border: 1px dotted rgba(0, 148, 20, 0.596);
      cursor: pointer;
    }

    .deleteButton {
      padding: 2px 5px;
      border-radius: 5px;
      color: crimson;
      border: 1px dotted rgba(220, 20, 60, 0.6);
      cursor: pointer;
    }
  }
`;

const TopBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  .search {
    display: flex;
    align-items: center;
    border: 0.5px solid lightgray;
    padding: 0.5rem;

    input {
      border: none;
      outline: none;
      background: transparent;

      &::placeholder {
        font-size: 12px;
      }
    }
  }
  @media screen and (max-width: 480px) {
    display: grid;
    gap: 1rem;

    .search {
      justify-self: flex-end;
    }
  }
`;

const Datatable = (props) => {
  const navigate = useNavigate();
  const { region } = props;
  const { sector } = props;

  const [tdata, setData] = useState([]);
  const location = useLocation();
  const docName = location.pathname.split("/")[1];
  const [query, setQuery] = useState("");

  const keys = ["title", "sector", "category", "lga", "contractor"];
  const search = (fdata) => {
    return fdata.filter(
      (item) =>
        keys.some(
          (key) => item[key] && item[key].toLowerCase().includes(query)
        ) &&
        item.lga.toLowerCase().includes(region.toLowerCase()) &&
        item.sector.toLowerCase().includes(sector.toLowerCase())
    );
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const checkFilter = async () => {
      try {
        const q = searchParams.get("q");
        if (q) {
          setQuery(q);
        }
      } catch (err) {
        console.log(err);
      }
    };
    const getUsers = async () => {
      try {
        const res = await userRequest.get(`project?q=${query}`);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (query.length === 0 || query.length > 2) getUsers();
    if (searchParams.get("q") && query.length === 0) checkFilter();
  }, [query, region, sector]);

  const handleRowClick = (e) => {
    navigate("/projects/" + e.id);
  };

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      headerAlign: "center",
      width: 100,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            <Link
              to={"/" + docName + "/" + params.row._id}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
          </div>
        );
      },
    },
  ];
  // const numb = [
  //   {
  //     name: "",
  //     label: "",
  //     options: {
  //       filter: false,
  //       customBodyRender: (value, tableMeta, update) => {
  //         let rowIndex = Number(tableMeta.rowIndex) + 1;
  //         return <span>{rowIndex}</span>;
  //       },
  //     },
  //   },
  // ];
  return (
    <DataTable>
      <TopBar>
        <h2>State Government Projects</h2>
        <p className="lnk">
          partners <BiArrowToRight />
        </p>
        <div className="search">
          <input
            type="text"
            placeholder="Search"
            onChange={(e) => setQuery(e.target.value.toLowerCase())}
          />
          <BiSearch />
        </div>
      </TopBar>
      <DataGrid
        className="datagrid"
        autoHeight
        rows={search(tdata)}
        onRowClick={(e) => {
          handleRowClick(e);
        }}
        getRowId={(row) => row._id}
        columns={
          docName === "budget"
            ? budgetColumns.concat(actionColumn)
            : docName === "suggestion"
            ? suggestionColumns.concat(actionColumn)
            : docName === "projects"
            ? projectColumns.concat(actionColumn)
            : reportColumns.concat(actionColumn)
        }
        pageSize={20}
        rowsPerPageOptions={[10]}

        // checkboxSelection
      />
    </DataTable>
  );
};

export default Datatable;
