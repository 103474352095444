import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { publicRequest } from "../../requestMethod";
import { MyResponsiveBar } from "../nivo/bar/Bar";
import { BarData } from "../nivo/data/bar";
import { LineData } from "../nivo/data/line";
// import { PieData } from "../nivo/data/Pie";
import { MyResponsiveLine } from "../nivo/line/Line";
import { MyResponsivePie } from "../nivo/pie/Pie";
import ContractorTable from "../table/ContractorTable";
import DashboardTable from "../table/Dashboard";
import SectorTable from "../table/SectorTable";

const PageHeader = styled.h1`
  text-align: center;
  color: #000;
`;

const SelectDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  select {
    border: 1px solid #ccc;
    padding: 0.4rem 1rem;
    margin: 1rem;
    font-size: 1.5rem;
    outline: none;
  }
`;

const MainContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  width: 100%;

  @media screen and (max-width: 950px) {
    grid-template-columns: 1fr;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  background-color: rgba(240, 240, 240, 0.5);
  padding: 1rem 0.5rem;

  h3 {
    text-transform: uppercase;
  }
`;

const Chart = styled.div`
  height: 100%;
  max-height: 450px;
  padding: 1rem;
  margin-bottom: 1rem;
  align-items: center;
  justify-content: center;
`;

const BottomDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;

  h4 {
    color: var(--first-color);
  }

  span {
    color: var(--fifth-color);

    &.total {
      color: var(--text-color-dark);
    }
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

const Perform = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem 1rem;

  @media screen and (max-width: 500px) {
    align-items: flex-start;
  }
`;

const Perf = styled.div`
  display: grid;
  width: 50%;

  &.scd {
    border-left: 2px solid #ccc;
    padding-left: 2rem;
  }

  h4 {
    color: var(--first-color);
  }

  h1 {
    font-size: 2.5rem;
    color: var(--fifth-color);
    font-weight: 500;
  }

  @media screen and (max-width: 500px) {
    &.scd {
      border-left: 0;
    }
  }
`;

const Dashboards = () => {
  const [proj, setProj] = useState([]);
  const [pComp, setPComp] = useState({});
  const [ProjStatus, setProjStatus] = useState([]);
  const [ProjLg, setProjLg] = useState([]);
  const [projYear, setProjYear] = useState(2022);
  let pD = [];
  let pL = [];

  const vst = proj.length;

  useEffect(() => {
    const getProj = async () => {
      try {
        const pro = await publicRequest.get("project");
        setProj(pro.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getProjComp = async () => {
      try {
        const comp = await publicRequest.get("project/allCompletion");
        setPComp(comp.data[0]);
      } catch (error) {
        console.log(error);
      }
    };
    const getProjStatus = async () => {
      try {
        const stt = await publicRequest.get("project/status");
        setProjStatus(stt.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getProjLga = async () => {
      try {
        const lg = await publicRequest.get("project/lga");
        setProjLg(lg.data);
        console.log(lg.data);
      } catch (error) {
        console.log(error);
      }
    };
    getProj();
    getProjComp();
    getProjStatus();
    getProjLga();
  }, []);

  // eslint-disable-next-line
  ProjLg.map((pd, i) => {
    pL.push({
      country: pd._id.lga,
      notStarted: pd._id.status === "NotStarted" ? pd.count : 0,
      notStartedColor: "hsl(55, 94%, 41%)",
      ongoing: pd._id.status === "OnGoing" ? pd.count : 0,
      ongoingColor: "hsl(273, 93%, 71%)",
      completed: pd._id.status === "Completed" ? pd.count : 0,
      completedColor: "hsl(121, 92%, 63%)",
      abandoned: pd._id.status === "Abandoned" ? pd.count : 0,
      abandonedColor: "hsl(6, 96%, 56%)",
    });
  });

  // eslint-disable-next-line
  ProjStatus.map((plg, i) => {
    pD.push({
      id: plg._id,
      label: plg._id,
      value: plg.count,
      color:
        plg._id === "NotStarted"
          ? "hsl(55, 94%, 41%)"
          : plg._id === "OnGoing"
          ? "hsl(273, 93%, 71%)"
          : plg._id === "Completed"
          ? "hsl(121, 92%, 63%)"
          : plg._id === "Abandoned" && "hsl(6, 96%, 56%)",
    });
  });

  const handleSelect = (e) => {
    setProjYear(e.target.value);
  };

  // const prct = (vst / proj.length) * 100;
  const prct = (vst / proj.length) * 100;
  return (
    <>
      <PageHeader>STATE PROJECT MONITORING</PageHeader>
      <SelectDiv>
        <select name="year" onChange={handleSelect}>
          <option value="">Year</option>
          <option value="2023">2023</option>
          <option value="2022" defaultValue={true}>
            2022
          </option>
          <option value="2021">2021</option>
          <option value="2020">2020</option>
        </select>
      </SelectDiv>
      <input type="hidden" name="yr" value={projYear} />
      <MainContainer>
        <Container>
          <h3>Projects Visited {projYear}</h3>
          <Chart>
            <div
              style={{ width: "100%", backgroundColor: "#DDDDDD" }}
              id="Progress_Status"
            >
              <div
                style={{
                  width: `${prct}%`,
                  height: 20,
                  backgroundColor: "#f47560",
                }}
                id="myprogressBar"
              ></div>
            </div>
            <BottomDiv>
              <h4>
                Number of Projects Visited: <span>{vst}</span>
              </h4>
              <h4>
                Total Number of projects:{" "}
                <span className="total">{proj.length}</span>
                {/* <span className="total">51</span> */}
              </h4>
            </BottomDiv>
          </Chart>
        </Container>
        <Container>
          <h3>Average Performance</h3>
          <Perform>
            <Perf>
              <h4>Average Completion</h4>
              <h1>{Math.floor(pComp.progress / pComp.count)}%</h1>
            </Perf>
            <Perf className="scd">
              <h4>Average Quality</h4>
              <h1>85.4%</h1>
            </Perf>
          </Perform>
        </Container>
        <Container>
          <h3>Status of Project Visited</h3>
          <Chart>
            <MyResponsivePie data={pD} />
          </Chart>
        </Container>
        <Container>
          <h3>Sector Performance</h3>
          <SectorTable />
        </Container>
        <Container>
          <h3>M.D.A Performance</h3>
          <DashboardTable />
        </Container>
        <Container>
          <h3>Contractor's Performance</h3>
          <ContractorTable />
        </Container>
        <Container>
          <h3>L.G.A Status</h3>
          <Chart>
            <MyResponsiveBar className="chart" data={BarData} />
          </Chart>
        </Container>
        <Container>
          <h3>Timeline Status</h3>
          <Chart>
            <MyResponsiveLine data={LineData} />
          </Chart>
        </Container>
      </MainContainer>
    </>
  );
};

export default Dashboards;
