import { createSlice } from "@reduxjs/toolkit";

const newsSlice = createSlice({
  name: "news",
  initialState: {
    news: [],
    isFetching: false,
    error: false,
  },
  reducers: {
    //Add News
    addNewsStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addNewsSuccess: (state, action) => {
      state.isFetching = true;
      state.news.push(action.payload);
    },
    addNewsFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },

    //Add Marker
    addMarkerStart: (state) => {
      state.isFetching = true;
      state.error = false;
    },
    addMarkerSuccess: (state, action) => {
      state.isFetching = true;
      state.marker.push(action.payload);
    },
    addMarkerFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
  },
});

export const {
  addNewsStart,
  addNewsSuccess,
  addNewsFailure,
  addMarkerStart,
  addMarkerSuccess,
  addMarkerFailure,
} = newsSlice.actions;
export default newsSlice.reducer;
