import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Project from "../components/singleProject/SingleProject";

const SingleProject = () => {
  return (
    <>
      <Project />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default SingleProject;
