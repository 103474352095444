import React, { useState, useEffect } from "react";
import { AiOutlineSend } from "react-icons/ai";
import styled from "styled-components";
import { publicRequest } from "../../requestMethod";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  background-color: var(--first-color);
  flex-direction: column;

  .success {
    color: #000;
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  i {
    display: inline-block;
    color: red;
    margin-top: 1rem;
  }

  @media screen and (max-width: 780px) {
    padding: 2rem 0;
    margin: 1rem auto;
  }
`;

const Topic = styled.h1`
  color: var(--white-text);
  text-align: center;
`;

const Intro = styled.p`
  color: var(--title-color);
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  width: 50%;
  height: 40px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;

  @media screen and (max-width: 780px) {
    width: 80%;
  }
`;

const Input = styled.input`
  border: none;
  flex: 8;
  padding-left: 20px;
  outline: none;
`;

const Button = styled.button`
  flex: 1;
  border: none;
  background-color: var(--title-color1);
  color: var(--white-text);

  @media screen and (max-width: 780px) {
    flex: 2;
  }
`;

const Newsletter = () => {
  const url = "/newsletter";

  const [data, setData] = useState({
    email: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && data.email !== "") {
      publicRequest
        .post(url, data)
        .then((res) => {
          setData({ email: "" });
        })
        .catch((error) => {
          setFormErrors({ email: error.response.data.msg });
        });
    }
  }, [formErrors, isSubmit, data]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    return errors;
  };

  return (
    <Container>
      {Object.keys(formErrors).length === 0 && isSubmit ? (
        <div className="success">
          Subscription was successfull, thank you for subscribing, we'll keep
          your email confidential.
        </div>
      ) : (
        ""
      )}
      <Topic>Subscribe to our Newsletter</Topic>
      <Intro>Get notified of new projects and project releases</Intro>
      <InputContainer>
        <Input
          name="email"
          className={formErrors.email ? "err" : isSubmit ? "crt" : undefined}
          onChange={(e) => handleChange(e)}
          placeholder="Your email"
          value={data.email}
        />
        <Button type="submit" onClick={(e) => handleSubmit(e)}>
          <AiOutlineSend />
        </Button>
      </InputContainer>
      <i>{formErrors.email}</i>
    </Container>
  );
};

export default Newsletter;
