import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Durable from "../components/dashboard/Durable";

const DurableSolution = () => {
  return (
    <>
      <Durable />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default DurableSolution;
