import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";
import BudgetTable from "../components/budget/BudgetPerformance";

const BudgetPerformance = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <BudgetTable />
      <Newsletter />
      <Sponsors />
      <Footer />
      <Footmark />
    </>
  );
};

export default BudgetPerformance;
