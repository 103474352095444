// import { Link } from "react-router-dom";

export const userColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "user",
    headerName: "User",
    headerClassName: "super-app-theme--header",
    headerAlign: "center",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./images/staff.png")}
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 280,
  },

  {
    field: "isAdmin",
    headerName: "Admin",
    headerAlign: "center",
    width: 100,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerAlign: "center",
    width: 180,
  },
];

export const newsletterColumns = [
  {
    field: "_id",
    headerName: "ID",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 170,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
  },
  {
    field: "status",
    headerName: "Status",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 300,
  },
  {
    field: "createdAt",
    headerName: "Date Subscribed",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
];

export const markerColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "notification",
    headerName: "Notification",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 230,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./images/default_marker.png")}
            alt="avatar"
          />
          {params.row.notification}
        </div>
      );
    },
  },
  {
    field: "longitude",
    headerName: "Longitude",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 160,
  },
  {
    field: "latitude",
    headerName: "Latitude",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 160,
  },
  {
    field: "information",
    headerName: "Information",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "userId",
    headerName: "Added By",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "createdAt",
    headerName: "Date Added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
];

export const contactColumns = [
  // { field: "_id", headerName: "ID", width: 170 },
  {
    field: "name",
    headerName: "Name",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 180,
  },
  {
    field: "email",
    headerName: "Email",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "phone",
    headerName: "Phone Number",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "subject",
    headerName: "Subject",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "message",
    headerName: "Message",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
  },
  {
    field: "createdAt",
    headerName: "Date Submitted",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 120,
  },
];

export const reportColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "report",
    headerName: "Reporter",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./images/sd-default-image.png")}
            alt="avatar"
          />
          {params.row.name}
        </div>
      );
    },
  },
  {
    field: "phone",
    headerName: "Phone",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "location",
    headerName: "Location",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "category",
    headerName: "Category",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "detailReport",
    headerName: "Report",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 230,
  },
  {
    field: "eventDate",
    headerName: "Date",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
];

export const suggestionColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "suggestion",
    headerName: "Name",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./images/sd-default-image.png")}
            alt="avatar"
          />
          {params.row.userId}
        </div>
      );
    },
  },
  {
    field: "sector",
    headerName: "Sector",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "lga",
    headerName: "L.G.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 150,
  },
  {
    field: "title",
    headerName: "Title",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "comment",
    headerName: "Comment",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 230,
  },
  {
    field: "createdAt",
    headerName: "Date added",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 110,
  },
];

export const partnerProjectColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "parnerName",
    headerName: "Partner Name",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "summary",
    headerName: "Summary",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "location",
    headerName: "Location",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "remark",
    headerName: "Remarks",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
];

export const projectColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "title",
    headerName: "Title",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
    renderCell: (params) => {
      return (
        <div className="cellWithImg">
          <img
            className="cellImg"
            src={params.row.img || require("./images/sd-default-image.png")}
            alt="avatar"
          />
          {params.row.title}
        </div>
      );
    },
  },
  {
    field: "sector",
    headerName: "Sector",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "lga",
    headerName: "L.G.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "ward",
    headerName: "Ward",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
  },
  {
    field: "cost",
    headerName: "Total Cost",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 130,
    renderCell: (params) => {
      return (
        params.row.cost &&
        params.row.cost.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },
  {
    field: "contractor",
    headerName: "Contractor",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 250,
  },
  {
    field: "progress",
    headerName: "Progress",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 100,
  },
  // {
  //   field: "longitude",
  //   headerName: "Coordinates",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 150,
  //   renderCell: (params) => {
  //     return (
  //       <div className="cellWithImg">
  //         <Link
  //           to={`/maps/?lat=${params.row.latitude}&lng=${params.row.longitude}`}
  //         >
  //           Map
  //         </Link>
  //       </div>
  //     );
  //   },
  // },
];

export const budgetColumns = [
  // { field: "_id", headerName: "ID", width: 100 },
  {
    field: "mdaId",
    headerName: "M.D.A",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 350,
    renderCell: (params) => {
      return params.row.mdaId.name;
    },
  },
  {
    field: "programme",
    headerName: "Programme Code & Description",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 320,
  },
  {
    field: "description",
    headerName: "Description",
    headerClassName: "table-header",
    headerAlign: "center",
    minWidth: 450,
  },
  // {
  //   field: "economic",
  //   headerName: "Economic Code & Description",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   minWidth: 200,
  // },
  // {
  //   field: "function",
  //   headerName: "Function Code & Description",
  //   headerClassName: "table-header",
  //   headerAlign: "center",
  //   width: 200,
  // },
  {
    field: "location",
    headerName: "Location Code & Description",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
  },
  {
    field: "amount",
    headerName: "Approved Budget",
    headerClassName: "table-header",
    headerAlign: "center",
    width: 200,
    renderCell: (params) => {
      return (
        params.row.amount &&
        params.row.amount.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    },
  },
];
