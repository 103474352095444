import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";
import Partners from "../components/partners/Partners";

const InternationalPartners = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <Partners />
      <Newsletter />
      <Sponsors />
      <Footer />
      <Footmark />
    </>
  );
};

export default InternationalPartners;
