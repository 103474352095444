import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Ng from "../components/grants/Ng-care";

const NgCares = () => {
  return (
    <>
      <Ng />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default NgCares;
