import Barner from "../header/Barner";
import "./navigation.css";
import { NavLink } from "react-router-dom";
import React, { useState } from "react";
import {
  FiAlignRight,
  FiXCircle,
  FiChevronDown,
  FiChevronRight,
} from "react-icons/fi";
import logo from "../../images/new_logo.png";

const Navigation = () => {
  const user =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;

  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);

  let boxClass = ["nav-links"];
  if (isMenu) {
    boxClass.push("nav-links-show");
  } else {
    boxClass.push("");
  }

  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };
  return (
    <>
      <Barner />
      <nav>
        <div className="navbar">
          {isResponsiveclose === true ? (
            <FiXCircle onClick={toggleClass} className="bx bx-menu" />
          ) : (
            <FiAlignRight onClick={toggleClass} className="bx bx-menu" />
          )}
          <div className={boxClass.join(" ")}>
            <ul className="links">
              <li>
                <NavLink className="NavLink" onClick={toggleClass} to={`/`}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  About Us
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="about-sub-menu sub-menu">
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/about_the_governor`}
                    >
                      The Executive Governor
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/about_commission`}
                    >
                      Planning Commission
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  Expenditure Tracking System
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="about-sub-menu sub-menu">
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/about`}
                    >
                      PETS & OGP
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/maps`}
                    >
                      Geo-tag Coordinates
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  Dashboards
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="about-sub-menu sub-menu">
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/dashboard`}
                    >
                      State Project Monitoring
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="NavLink" onClick={toggleClass} to={`#`}>
                      State Projects/Programs Matrix
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`partners_dashboard`}
                    >
                      Partners Project Monitoring
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/partners_matrix`}
                    >
                      Partners Coordination Matrix
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/lga_dashboard`}
                    >
                      LGA Project Monitoring
                    </NavLink>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Durable Solutions Strategic Plans
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/durable_solutions`}
                        >
                          Adamawa DS Action plan
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/durable_solutions`}
                        >
                          Durable Solution Matrix
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  Projects
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="about-sub-menu sub-menu">
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/projects`}
                    >
                      State Projects
                    </NavLink>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Partner's Projects
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      {/* <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Coordination Matrix
                        </NavLink>
                      </li> */}
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/international_partners`}
                        >
                          International NGO
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/national_partners`}
                        >
                          National NGO
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/local_partners`}
                        >
                          Local NGO
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Fed.Govt. intervention proj./prog.
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ecological Funded Projects
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Northeast Dev. Comm. Proj.
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          NEMA Proj./Prog.
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  MDA Scorecards
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="about-sub-menu sub-menu">
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Administrative Sector
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Office Of The Deputy Governor
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Office Of The Secretary To The State Government
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Bureau For Public Procurement
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Fiscal Responsibility Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State House Of Assembly (Legislature)
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          House Of Assembly Service Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Information And Strategy
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Staff Pension Board
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Office Of The State Auditor General
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Civil Service Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Independent Electoral Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Local Government Service Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Emergency Management Agency (Adsema)
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Economic Sector
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Agriculture
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Budget And Economic Planning
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Commerce, Trade And Industries
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Finance
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Labour And Productivity
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Transportation
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Mineral Resources Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Mining Company
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Works And Energy Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Culture And Tourism
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Planning Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Water Resources
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Housing And Urban Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Lands And Survey
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Office Of The Surveyor General
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Livestock & Aquaculture Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Entrepreneurship Development
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Law And Justice Sector
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Judicial Service Commission
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Justice
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Regional Sector
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Reconstruction, Rehab, Reintegration
                          Humanitarian Service
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Social Sector
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Youth & Sports Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Women Affairs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Education And Human Capital Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Health And Human Services
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Adamawa State Contributory Health Management Agency
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Environment And Natural Resources
                          Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry For Local Government Affairs
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Rural Infrastructure & Community
                          Development
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Local Government Staff Pension Board
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          Ministry Of Chieftaincy Affairs
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" to={`#`}>
                  Grants/Facilities
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down about-arrow arrow  " />
                <ul className="js-sub-menu sub-menu">
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/grants`}
                    >
                      Grants Supported Projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/credits`}
                    >
                      Credit Supported Projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/loan`}
                    >
                      Loan Supported Projects
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="NavLink"
                      onClick={toggleClass}
                      to={`/ng-cares`}
                    >
                      NG-CARES' Supported Projects
                    </NavLink>
                  </li>
                </ul>
              </li>
              <li>
                {/* <NavLink className="NavLink" to={`#`}>
                  Budgets & Mtef
                </NavLink> */}
                <NavLink className="NavLink" to={`#`}>
                  Budgets/Dev. Plans
                </NavLink>
                <FiChevronDown className="bx bxs-chevron-down htmlcss-arrow arrow  " />
                <ul className="htmlCss-sub-menu sub-menu">
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        State Budget
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/APPROVED-2020-BUDGET-OF-ADAMAWA-STATE-FINAL.pdf&embedded=true"
                        >
                          2020
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2021-APPROVED-BUDGET-OF-ADAMAWA-STATE.pdf&embedded=true"
                        >
                          2021
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/Adamawa%20State%20FY%202022%20Budget%20-%20Publication%20Version%20Final%209th%20nov.xlsx&embedded=true"
                        >
                          2022
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          // href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2023%20Approved%20Budget%20(1).pdf&embedded=true"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/ADAMAWA%20Budget%20of%20Consolidation%202023%20SUPPLEMENTARY%20(1).pdf&embedded=true"
                        >
                          2023
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2024%20Approved%20Budget%20of%20Adamawa%20State%20of%20Nigeria%20Multi%20Year%20Budget%20(5).pdf&embedded=true"
                        >
                          2024
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Citizens Budget
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/citizens_budget`}
                        >
                          2020
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/citizens_budget`}
                        >
                          2021
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/citizens_budget`}
                        >
                          2022
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`/citizens_budget`}
                        >
                          2023
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Budget Performance Report
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          2020
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          2021
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          2022
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          2023
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                  <li className="more">
                    <span>
                      <NavLink className="NavLink" to={`#`}>
                        Mtef
                      </NavLink>
                      <FiChevronRight className="bx bxs-chevron-right arrow more-arrow" />
                    </span>
                    <ul className="more-sub-menu sub-menu">
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2021-2023-Adamawa-State-Approved-MTEF-FSP-MTEF-FSP.pdf&embedded=true"
                        >
                          2021 - 2023
                        </a>
                      </li>
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2022-2024-Adamawa-State-Approved-MTEF-FSP.pdf&embedded=true"
                        >
                          2022 - 2024
                        </a>
                      </li>
                      {/* <li>
                        <NavLink
                          className="NavLink"
                          onClick={toggleClass}
                          to={`#`}
                        >
                          2023
                        </NavLink>
                      </li> */}
                      <li>
                        <a
                          onClick={toggleClass}
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://docs.google.com/gview?url=https://api.admehub.ng/uploads/budgets/2024-Approved-MTEF.pdf&embedded=true"
                        >
                          2024 - 2026
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <NavLink className="NavLink" onClick={toggleClass} to={`/news`}>
                  News
                </NavLink>
              </li>
              <li>
                <NavLink
                  className="NavLink"
                  onClick={toggleClass}
                  to={`/Contact`}
                >
                  Contact
                </NavLink>
              </li>
              {user ? (
                <li>
                  <NavLink
                    className="NavLink"
                    onClick={toggleClass}
                    to={`/suggestion`}
                  >
                    Suggest a project
                  </NavLink>
                </li>
              ) : (
                <li>
                  <NavLink
                    className="NavLink"
                    onClick={toggleClass}
                    to={`/login`}
                  >
                    Sign in to participate
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          <div className="logo">
            <img src={logo} alt="logo" />
            <p className="comp_name">
              Adamawa State <br />
              Technological Monitoring Hub <br />
              (CITIZENS' EYES AND EARS)
            </p>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
