import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import AboutPage from "../components/about/Planning";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";
// import CommissionAccordion from "../components/accordion/Commission";

function AboutPlanning() {
  return (
    <>
      <AboutPage />
      {/* <CommissionAccordion /> */}
      <Sponsors />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
}

export default AboutPlanning;
