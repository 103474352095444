export const LineData = [
  {
    id: "NotStarted",
    color: "hsl(55, 94%, 41%)",
    data: [
      {
        x: "Jan",
        y: 250,
      },
      {
        x: "Feb",
        y: 192,
      },
      {
        x: "Mar",
        y: 271,
      },
      {
        x: "Apr",
        y: 121,
      },
      {
        x: "May",
        y: 261,
      },
      {
        x: "Jun",
        y: 247,
      },
      {
        x: "Jul",
        y: 88,
      },
      {
        x: "Aug",
        y: 51,
      },
      {
        x: "Sep",
        y: 164,
      },
      {
        x: "Oct",
        y: 61,
      },
      {
        x: "Nov",
        y: 169,
      },
      {
        x: "Dec",
        y: 229,
      },
    ],
  },
  {
    id: "Ongoing",
    color: "hsl(273, 93%, 71%)",
    data: [
      {
        x: "Jan",
        y: 48,
      },
      {
        x: "Feb",
        y: 285,
      },
      {
        x: "Mar",
        y: 40,
      },
      {
        x: "Apr",
        y: 265,
      },
      {
        x: "May",
        y: 59,
      },
      {
        x: "Jun",
        y: 287,
      },
      {
        x: "Jul",
        y: 141,
      },
      {
        x: "Aug",
        y: 187,
      },
      {
        x: "Sep",
        y: 126,
      },
      {
        x: "Oct",
        y: 202,
      },
      {
        x: "Nov",
        y: 59,
      },
      {
        x: "Dec",
        y: 234,
      },
    ],
  },
  {
    id: "Completed",
    color: "hsl(121, 92%, 63%)",
    data: [
      {
        x: "Jan",
        y: 93,
      },
      {
        x: "Feb",
        y: 224,
      },
      {
        x: "Mar",
        y: 65,
      },
      {
        x: "Apr",
        y: 10,
      },
      {
        x: "May",
        y: 293,
      },
      {
        x: "Jun",
        y: 158,
      },
      {
        x: "Jul",
        y: 176,
      },
      {
        x: "Aug",
        y: 116,
      },
      {
        x: "Sep",
        y: 135,
      },
      {
        x: "Oct",
        y: 143,
      },
      {
        x: "Nov",
        y: 8,
      },
      {
        x: "Dec",
        y: 272,
      },
    ],
  },
  {
    id: "Abandoned",
    color: "hsl(6, 96%, 56%)",
    data: [
      {
        x: "Jan",
        y: 0,
      },
      {
        x: "Feb",
        y: 0,
      },
      {
        x: "Mar",
        y: 0,
      },
      {
        x: "Apr",
        y: 0,
      },
      {
        x: "May",
        y: 0,
      },
      {
        x: "Jun",
        y: 0,
      },
      {
        x: "Jul",
        y: 0,
      },
      {
        x: "Aug",
        y: 0,
      },
      {
        x: "Sep",
        y: 0,
      },
      {
        x: "Oct",
        y: 0,
      },
      {
        x: "Nov",
        y: 0,
      },
      {
        x: "Dec",
        y: 0,
      },
    ],
  },
];
