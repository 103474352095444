import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Recaptcha from "react-recaptcha";
import {
  FaFacebook,
  FaTwitter,
  FaYoutube,
  FaPhoneAlt,
  FaFolderPlus,
} from "react-icons/fa";
import { ImLocation2, ImMail2 } from "react-icons/im";
import { publicRequest } from "../../requestMethod";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem 1rem;
  margin: 1rem auto;

  @media screen and (max-width: 1020px) {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 90%;
  max-width: 80vw;
  margin: 50px auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: aqua; */

  @media screen and (max-width: 1020px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    width: 100%;
    margin: 0 20px;
  }
`;

const Left = styled.div`
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  background-color: var(--body-color);
  color: #fff;
  border-radius: 8px;

  h1 {
    color: #000;
    font-size: 2.5rem;
    margin: 2rem auto;
    font-weight: 500;
  }

  p {
    font-size: 1.1rem;
    color: #000;
    line-height: 1.8rem;
  }

  p.hpy {
    font-size: 1.5rem;
    color: var(--fifth-color);
    margin-left: -4rem;
    line-height: 1.8rem;
  }

  div {
    margin: 2rem -4rem;

    .icn {
      float: left;
      margin-left: -7rem;
      font-size: 2rem;
      border-radius: 50%;
      padding: 0.2rem;
      border: 1px solid #f5f2f5;
      color: var(--third-color);
    }
  }

  @media screen and (max-width: 760px) {
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      margin: 1rem;
    }

    p.hpy {
      font-size: 1.2rem;
      margin-left: 0;
    }

    div {
      margin: 1rem auto;
      padding: 0 1rem;

      .icn {
        margin-left: -1rem;
      }
    }
  }
`;
const Right = styled.div`
  width: 50%;
  min-height: 400px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
  border-radius: 8px;
  color: white;
  margin-left: -10vw;

  h1 {
    font-size: 2.5rem;
    font-weight: lighter;
    color: var(--fourth-color);
  }

  p {
    margin: 20px 0;
    font-weight: 500;
    line-height: 25px;
  }

  @media screen and (max-width: 760px) {
    width: 95%;
    margin: 0;
    margin-top: -120px;
  }
`;

const FormFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const BottomDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;

  @media screen and (max-width: 760px) {
    grid-template-columns: 1fr;
  }
`;

const Content = styled.div`
  width: 90%;
  color: var(--text-color);

  h1 {
    color: var(--fourth-color);
    margin-top: 1rem;
  }
  form {
    width: 100%;
  }

  label {
    display: none;
    margin: 0.5rem 0 1rem 0;
    color: var(--first-color);
    font-weight: 500;
    letter-spacing: 0.4rem;
    font-size: 1rem;
  }

  i {
    display: inline-block;
    color: hsl(0, 92%, 76%);
    margin-bottom: 4px;
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  .message {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(155, 50%, 75%);
    margin: 0.5rem;
    background-color: hsl(125, 90%, 75%);
    font-weight: 500;
  }

  .axios_error {
    display: flex;
    color: #fff;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(9, 98%, 51%);
    margin: 0.5rem;
    background-color: hsl(9, 82%, 55%);
    font-weight: 500;
  }

  input {
    padding: 1rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    float: left;
    margin: 0.5rem 0;
    outline: none;
  }

  input.file {
    padding: 1rem;
    float: left;
    margin: 0.5rem 0;
    outline: none;
  }

  textarea {
    padding: 1rem;
    clear: both;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 200%;
    outline: none;
  }

  input.submit {
    width: 100%;
    background-color: var(--first-color);
    border-radius: 0.4rem;
    border: 0;
    margin-top: 1.5rem;
    color: #fff;
    font-size: 1.5rem;
  }

  @media screen and (max-width: 760px) {
    width: 100%;

    form {
      width: 100%;
    }

    textarea {
      width: 100%;
    }
  }
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.8rem;

  & > * {
    margin: 1rem 2rem;
    cursor: pointer;
  }

  & > *:hover {
    background: #fff;
  }
`;

const Contact = () => {
  const url = "/contact";

  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  // specifying your onload callback function
  var callback = function () {
    console.log("Done!!!!");
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    if (response) {
      setIsVerified(true);
      // console.log(response);
    }
  };

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      data.name !== "" &&
      isVerified
    ) {
      publicRequest
        .post(url, data)
        .then((res) => {
          setData({
            name: "",
            email: "",
            phone: "",
            subject: "",
            message: "",
          });
        })
        .catch((error) => {
          setFormErrors({
            gen: "Something went wrong! please try again...",
          });
        });
    }
  }, [formErrors, isSubmit, data, isVerified]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "What is your Name!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (!regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phone) {
      errors.phone = "How may we contact you?";
    }
    if (!values.subject) {
      errors.subject = "Tell us why you are writting to us!";
    }

    if (!values.message) {
      errors.message = "Message is required!";
    }
    return errors;
  };
  return (
    <InfoDiv>
      <Container>
        <Left>
          <div>
            <h1>Lets get in Touch</h1>
            <p className="hpy">We are always happy to hear from you.</p>

            <div>
              <ImLocation2 className="icn" />
              <div className="ctn">
                <p>
                  Adamawa Planning Commision, State Secretariat Complex, Yola
                  <br />
                  Adamawa State. Nigeria.
                </p>
              </div>
            </div>
            <div>
              <FaPhoneAlt className="icn" />
              <div className="ctn">
                <p>07062259055</p>
                <p>07045955257</p>
              </div>
            </div>
            <div>
              <ImMail2 className="icn" />
              <div>
                <p>adamawaeyesandears@gmail.com</p>
                <p>info@admehub.ng</p>
              </div>
            </div>

            <SocialIcons>
              <FaFacebook
                style={{
                  borderRadius: "50%",
                  padding: "0.1rem",
                  fontSize: "2rem",
                  color: "#3b5998",
                }}
              />
              <FaTwitter
                style={{
                  borderRadius: "50%",
                  padding: "0.1rem",
                  fontSize: "2rem",
                  color: "#00acee",
                }}
              />
              <FaYoutube
                style={{
                  borderRadius: "50%",
                  padding: "0.1rem",
                  fontSize: "2rem",
                  color: "#FF0000",
                }}
              />
            </SocialIcons>
          </div>
        </Left>
        <Right>
          <Content>
            <h1>Contact Us</h1>
            <p>Send us a message</p>
            {Object.keys(formErrors).length === 0 && isSubmit ? (
              <div className="ui message success">
                Message sent successfully, thank you for contacting us, we'll
                get back to you soon!
              </div>
            ) : (
              ""
            )}
            {formErrors.gen && <i className="axios_error">{formErrors.gen}</i>}
            <form onSubmit={(e) => handleSubmit(e)}>
              <FormFields>
                <div>
                  {/* <label htmlFor="contact">Name</label> */}
                  <input
                    type="text"
                    className={
                      formErrors.name ? "err" : isSubmit ? "crt" : undefined
                    }
                    name="name"
                    placeholder="Name"
                    onChange={(e) => handleChange(e)}
                    value={data.name}
                    required
                  />
                  <i>{formErrors.name}</i>
                </div>
                <div>
                  {/* <label htmlFor="email">Email</label> */}
                  <input
                    type="email"
                    className={
                      formErrors.email ? "err" : isSubmit ? "crt" : undefined
                    }
                    name="email"
                    placeholder="Email"
                    onChange={(e) => handleChange(e)}
                    value={data.email}
                    required
                  />
                  <i>{formErrors.email}</i>
                </div>
                <div>
                  {/* <label htmlFor="email">Email</label> */}
                  <input
                    type="text"
                    className={
                      formErrors.phone ? "err" : isSubmit ? "crt" : undefined
                    }
                    name="phone"
                    placeholder="Phone Number"
                    onChange={(e) => handleChange(e)}
                    value={data.phone}
                    required
                  />
                  <i>{formErrors.phone}</i>
                </div>
                <div>
                  {/* <label htmlFor="email">Email</label> */}
                  <input
                    type="text"
                    className={
                      formErrors.subject ? "err" : isSubmit ? "crt" : undefined
                    }
                    name="subject"
                    placeholder="Subject"
                    onChange={(e) => handleChange(e)}
                    value={data.subject}
                    required
                  />
                  <i>{formErrors.subject}</i>
                </div>
                <div>
                  {/* <label htmlFor="message">Message</label> */}
                  <textarea
                    name="message"
                    className={
                      formErrors.message ? "err" : isSubmit ? "crt" : undefined
                    }
                    rows="5"
                    placeholder="Message"
                    onChange={(e) => handleChange(e)}
                    value={data.message}
                    required
                  ></textarea>
                  <i>{formErrors.message}</i>
                </div>
              </FormFields>
              <BottomDiv>
                <div className="fileDiv">
                  <label htmlFor="file">
                    File: <FaFolderPlus />
                  </label>
                  <input className="file" type="file" id="file" />
                </div>
                <Recaptcha
                  sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
                  render="explicit"
                  onloadCallback={callback}
                  verifyCallback={verifyCallback}
                />
              </BottomDiv>

              <div className="sbmt-block">
                <input
                  className="submit"
                  type="submit"
                  name="submit"
                  disabled={!isVerified}
                  value="Send Message"
                />
              </div>
            </form>
          </Content>
        </Right>
      </Container>
    </InfoDiv>
  );
};

export default Contact;
