export const PartnerData = [
  {
    country: "Demsa",
    projects: 10,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Girei",
    projects: 8,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Gombi",
    projects: 6,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Guyuk",
    projects: 12,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Hong",
    projects: 2,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Jada",
    projects: 6,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Lamurde",
    projects: 7,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Madagali",
    projects: 4,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Mayo-Belwa",
    projects: 2,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Michika",
    projects: 2,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Mubi-North",
    projects: 1,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Mubi-South",
    projects: 8,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Numan",
    projects: 10,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Yola North",
    projects: 11,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
  {
    country: "Yola South",
    projects: 9,
    notStartedColor: "hsl(351, 70%, 50%)",
  },
];
