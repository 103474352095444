import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  useLoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
} from "@reach/combobox";
// import { formatRelative } from "date-fns";

import "@reach/combobox/styles.css";
import mapStyles from "../mapStyles";
import { BiArrowBack, BiSearch } from "react-icons/bi";
import "../Map.css";
import { Link } from "react-router-dom";
import { publicRequest } from "../requestMethod";

const libraries = ["places"];
const mapContainerStyle = {
  height: "100vh",
  width: "100vw",
};
const options = {
  styles: mapStyles,
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: true,
};
let center = {
  lat: 9.397967,
  lng: 11.993396,
};

const Maps = (props) => {
  const url = "/project/map";
  const [markers, setMarkers] = React.useState([]);
  const [selected, setSelected] = React.useState(null);

  useEffect(() => {
    props.funcNav(false);
  }, [props]);

  useEffect(() => {
    const searchParams = new URLSearchParams(document.location.search);
    const getMarkers = async () => {
      try {
        const res = await publicRequest.get(url);
        // console.log(res.data[9].longitude + " " + res.data[9].latitude);
        setMarkers(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const goTo = async () => {
      try {
        const long = searchParams.get("lng");
        const lati = searchParams.get("lat");
        console.log(`${long} ${lati}`);
        // panTo(lati, long);
      } catch (error) {
        console.log(error);
      }
    };
    getMarkers();
    if (searchParams.get("lng")) goTo();
  }, []);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });

  // const onMapClick = React.useCallback((e) => {
  //   setMarkers((current) => [
  //     ...current,
  //     {
  //       lat: e.latLng.lat(),
  //       lng: e.latLng.lng(),
  //       time: new Date(),
  //     },
  //   ]);
  // }, []);

  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);

  const panTo = React.useCallback(({ lat, lng }) => {
    mapRef.current.panTo({ lat, lng });
    mapRef.current.setZoom(17);
  }, []);

  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";

  return (
    <div>
      <Link to="/">
        <BiArrowBack className="mbl-link" />
      </Link>
      {/* <h1 className="maps">
        <Link className="backlink" to="/">
          <BiArrowBack />
        </Link>
        Project Map
      </h1> */}
      <Locate panTo={panTo} />
      <Search panTo={panTo} />
      <GoogleMap
        id="map"
        mapContainerStyle={mapContainerStyle}
        zoom={11}
        center={center}
        options={options}
        // onClick={onMapClick}
        onLoad={onMapLoad}
      >
        {markers.map((marker) => (
          <Marker
            key={marker._id}
            position={{
              lat: parseFloat(marker.longitude),
              lng: parseFloat(marker.latitude),
            }}
            onClick={() => {
              setSelected(marker);
            }}
            icon={{
              url: `https://firebasestorage.googleapis.com/v0/b/ibro-shop.appspot.com/o/1669357340632default_marker.png?alt=media&token=c8fe1092-62c4-44b7-be50-7ea53cd7844e`,
              origin: new window.google.maps.Point(0, 0),
              anchor: new window.google.maps.Point(25, 25),
              scaledSize: new window.google.maps.Size(30, 30),
            }}
          />
        ))}

        {selected ? (
          <InfoWindow
            position={{ lat: selected.longitude, lng: selected.latitude }}
            onCloseClick={() => {
              setSelected(null);
            }}
          >
            <div>
              <div className="img-cont">
                <img
                  src={selected.img}
                  alt={selected.title}
                  className="map-img"
                />
              </div>
              <h2>{selected.title}</h2>
              <p>
                <strong>L.G.A: </strong> {selected.lga}
              </p>
              <p>
                <strong> M.D.A: </strong>
                {selected.mda}
              </p>
              <p>
                <strong> Cost: </strong>
                {selected.cost
                  ? selected.cost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : null}
              </p>
              <p>
                <strong> Status: </strong>
                {selected.status}
              </p>
              <p>
                <strong> Contractor: </strong>
                {selected.contractor}
              </p>
              <p>
                <strong>Coordinates: </strong>
                {selected.longitude}-{selected.latitude}
              </p>
            </div>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </div>
  );
};

function Locate({ panTo }) {
  return (
    <button
      className="locate"
      onClick={() => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            panTo({
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            });
          },
          () => null
        );
      }}
    >
      <Link className="backlink" to="/">
        Back to Homepage
      </Link>
      <img src="/compass.svg" alt="compass" />
    </button>
  );
}

// function Search({ panTo }) {
//   const {
//     ready,
//     value,
//     suggestions: { status, data },
//     setValue,
//     clearSuggestions,
//   } = usePlacesAutocomplete({
//     requestOptions: {
//       location: { lat: () => 9.3265, lng: () => 12.3984 },
//       radius: 100 * 1000,
//     },
//   });

function Search({ panTo }) {
  const [searchQuery, setSearchQuery] = useState("");
  // const [placesSuggestions, setPlacesSuggestions] = useState([]);
  const [projectResults, setProjectResults] = useState([]);
  const [projectSelected, setProjectSelected] = useState(false);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      location: { lat: () => 9.3265, lng: () => 12.3984 },
      radius: 100 * 1000,
    },
  });

  const handleInput = (e) => {
    setValue(e.target.value);
    setSearchQuery(e.target.value);
    setProjectSelected(false); // Reset project selection when input changes
  };

  const handleSelect = async (address) => {
    setValue(address, false);
    clearSuggestions();

    try {
      const results = await getGeocode({ address });
      const { lat, lng } = getLatLng(results[0]);
      panTo({ lat, lng });
    } catch (error) {
      console.log("😱 Error: ", error);
    }
  };

  const searchProjects = async () => {
    // try {
    //   const response = await publicRequest.get(`/project?q=${searchQuery}`);
    //   setProjectResults(response.data);
    // } catch (error) {
    //   console.error("Error searching projects:", error);
    // }
    // try {
    //   const response = await publicRequest.get(`/project?q=${searchQuery}`);
    //   const filteredResults = response.data.map((project) => {
    //     const filteredProject = {};
    //     const keys = ["title", "sector", "category", "lga", "contractor"];
    //     keys.forEach((key) => {
    //       if (
    //         project[key] &&
    //         project[key].toLowerCase().includes(searchQuery.toLowerCase())
    //       ) {
    //         filteredProject[key] = project[key];
    //       }
    //     });
    //     return filteredProject;
    //   });
    //   setProjectResults(filteredResults);
    // } catch (error) {
    //   console.error("Error searching projects:", error);
    // }
    try {
      const response = await publicRequest.get(`/project?q=${searchQuery}`);
      const filteredResults = response.data
        .map((project) => {
          const filteredProject = {
            title: project.title,
            longitude: project.longitude,
            latitude: project.latitude,
          };
          return filteredProject;
        })
        .filter((filteredProject) => {
          return Object.values(filteredProject).some(
            (value) =>
              typeof value === "string" &&
              value.toLowerCase().includes(searchQuery.toLowerCase())
          );
        });
      setProjectResults(filteredResults);
    } catch (error) {
      console.error("Error searching projects:", error);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchProjects();
    }
  };

  const handleProjectSelect = async (project) => {
    // console.log(project);
    const { longitude, latitude } = project;
    // console.log(longitude + " " + latitude);
    panTo({ lat: longitude, lng: latitude });
    setProjectSelected(true); // Set project selection to true when a project is clicked
  };

  return (
    <div className="map-search">
      <Combobox onSelect={handleSelect}>
        <div className="searchDiv">
          <ComboboxInput
            className="srch_in"
            value={value}
            onKeyDown={handleKeyPress}
            onChange={handleInput}
            disabled={!ready}
            placeholder="Search your location"
          />
          <BiSearch className="srch_btn" onClick={searchProjects} />
        </div>
        <ComboboxPopover>
          <ComboboxList>
            {status === "OK" &&
              data.map(({ id, description }) => (
                <ComboboxOption key={id} value={description} />
              ))}
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
      {/* <button onClick={searchProjects}>Search Projects</button> */}
      <ul className="projectSuggest">
        {!projectSelected &&
          projectResults.map((project) => (
            <li key={project._id} onClick={() => handleProjectSelect(project)}>
              {project.title}
            </li>
          ))}
      </ul>
    </div>
  );
}
export default Maps;
