import React, { useEffect, useState } from "react";
import "./project.css";
import styled from "styled-components";
import { publicRequest } from "../../requestMethod";
import Datatable from "../datatable/Datatable";
// import imgOne from "../../images/fly-over.jpg";
// import imgTwo from "../../images/PowerClip.jpg";
// import imgThree from "../../images/PowerClip1.jpg";
// import imgFour from "../../images/school-model.jpg";
// import { Link } from "react-router-dom";

const OuterContainer = styled.div`
  padding: 1rem;
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;

  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;

const Lgas = styled.div`
  select {
    display: none;
  }

  p {
    margin: 0;
    background-color: var(--first-color);
    color: #fff;
    padding: 0.3rem 1rem;
    font-weight: 500;
  }

  ul {
    border: 1px solid #ccc;
    padding: 0rem;
    background-color: var(--light-bg);

    li {
      margin: 0.5rem 0;
      padding: 0 0.5rem;
      font-weight: 400;
      color: var(--fifth-color);
    }

    li.selectd {
      color: var(--first-color);
    }

    li:not(:last-child) {
      border-bottom: 1px solid #ccc;
    }
  }

  @media screen and (max-width: 780px) {
    select {
      display: inline-block;
      width: 100%;
      padding: 0.5rem;
      outline: 0;
      border: 1px solid #ccc;

      option {
        padding: 1rem;
        font-size: 1rem;
      }
    }

    ul {
      display: none;
    }
  }
`;
const TableContainer = styled.div`
  Link {
    margin: 0 2rem;
    color: var(--fourth-color);
  }
`;
// const ProjectDiv = styled.div`
//   align-items: center;
//   justify-content: center;

//   p {
//     margin: 0;
//     background-color: var(--first-color);
//     color: #fff;
//     padding: 1rem;
//     padding: 0.3rem 1rem;
//     font-weight: 500;
//   }

//   div.photostream {
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     border: 1px solid #ccc;
//     gap: 3px;

//     img {
//       width: 150px;
//       margin: 0.3rem;
//     }
//   }

//   @media screen and (max-width: 780px) {
//     div.photostream {
//       grid-template-columns: repeat(3, 1fr);

//       img {
//         width: 200px;
//         margin: 0.3rem;
//       }
//     }
//   }
// `;

const Project = () => {
  const [lgdata, setLgas] = useState([]);
  const [sctdata, setSct] = useState([]);
  const [region, setRegion] = useState("");
  const [sector, setSector] = useState("");

  useEffect(() => {
    const getLgas = async () => {
      try {
        const lgas = await publicRequest.get("/lgas");
        setLgas(lgas.data);
      } catch (error) {
        console.log(error);
      }
    };
    const getSector = async () => {
      try {
        const sect = await publicRequest.get("/sector");
        setSct(sect.data);
      } catch (error) {
        console.log(error);
      }
    };
    getLgas();
    getSector();
  }, []);

  const handleSelect = (event) => {
    // console.log(event.target.getAttribute("data-value"));
    setRegion(event.target.getAttribute("data-value"));
  };

  const handleSelected = (event) => {
    // console.log(event.target.getAttribute("data-value"));
    setRegion(event.target.value);
  };

  const handleSelect1 = (event) => {
    // console.log(event.target.getAttribute("data-value"));
    setSector(event.target.getAttribute("data-value"));
  };

  const handleSelected1 = (event) => {
    // console.log(event.target.getAttribute("data-value"));
    setSector(event.target.value);
  };

  return (
    <OuterContainer>
      <Lgas>
        <p>Local Gov't. Areas</p>
        <ul>
          <li
            onClick={handleSelect}
            data-value=""
            className={region === "" ? "selectd" : null}
          >
            All
          </li>
          {lgdata.map((lga) => (
            <li
              key={lga._id}
              onClick={handleSelect}
              data-value={lga.name}
              className={region === lga.name ? "selectd" : null}
            >
              {lga.name}
            </li>
          ))}
        </ul>
        <select name="region" onChange={handleSelected}>
          <option value="">All</option>
          {lgdata.map((lga) => (
            <option value={lga.name} key={lga._id}>
              {lga.name}
            </option>
          ))}
        </select>
      </Lgas>
      <TableContainer>
        {/* <Link to="/maps">Project Map</Link> */}
        <Datatable region={region} sector={sector} />
      </TableContainer>
      <>
        <Lgas>
          <p>Sectors</p>
          <ul>
            <li
              onClick={handleSelect1}
              data-value=""
              className={sector === "" ? "selectd" : null}
            >
              All
            </li>
            {sctdata.map((sct) => (
              <li
                key={sct._id}
                onClick={handleSelect1}
                data-value={sct.name}
                className={sector === sct.name ? "selectd" : null}
              >
                {sct.name}
              </li>
            ))}
          </ul>
          <select name="sector" onChange={handleSelected1}>
            <option value="">All</option>
            {sctdata.map((sct) => (
              <option value={sct.name} key={sct._id}>
                {sct.name}
              </option>
            ))}
          </select>
        </Lgas>
        {/* <ProjectDiv>
          <p>Featured Projects</p>
          <div className="photostream">
            <img src={imgOne} alt="" />
            <img src={imgTwo} alt="" />
            <img src={imgThree} alt="" />
            <img src={imgFour} alt="" />
            <img src={imgOne} alt="" />
            <img src={imgTwo} alt="" />
            <img src={imgThree} alt="" />
            <img src={imgFour} alt="" />
          </div>
        </ProjectDiv> */}
      </>
    </OuterContainer>
  );
};

export default Project;
