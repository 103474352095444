import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
// import { Button } from "../Button";
import moment from "moment";

import { publicRequest, userRequest } from "../../requestMethod";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem 5rem;
  line-height: 2rem;
  display: inline-block;

  img {
    float: left;
    width: 500px;
    margin: 2rem;
  }

  h1 {
    text-align: center;
    font-size: 2rem;
    margin-bottom: 0.5rem;
    line-height: 3rem;
    color: var(--fourth-color);
  }

  p.dateP {
    margin: 0;
    text-align: flex-start;
    color: var(--text-color-light);
  }

  p {
    margin-bottom: 2rem;
    // display: inline-block;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    margin: 1rem auto;

    img {
      margin: 0rem;
    }

    h1 {
      font-size: 1.6rem;
      line-height: 2rem;
      margin-bottom: 0.3rem;
    }
  }
`;

const Comment = styled.div`
  width: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    margin: 0 4rem;
    border-bottom: 1px solid #999;
  }

  div {
    margin: 1rem 2rem;
  }

  div.btn-div {
    width: 80%;
    display: flex;
    margin-top: 0.5rem;
    align-item: center;
    justify-content: center;
  }

  label {
    font-weight: 500;
    margin: 0 1rem;
    color: #000;
  }

  input {
    width: 80%;
    padding: 1rem;
    border: 1px solid #ccc;
    outline: 0;
  }

  textarea {
    width: 80%;
    padding: 1rem;
    border: 1px solid #ccc;
    outline: 0;
  }

  button {
    padding: 0.7rem 1rem;
    height: 50%;
    background-color: var(--first-color);
    color: #fff;
    border-radius: 0.3rem;
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 480px) {
    form {
      flex-direction: column;
      align-items: flex-start;

      textarea {
        width: 100%;
        margin-bottom: 0.5rem;
      }

      button {
        width: 100%;
      }
    }

    h4 {
      margin: 0 1rem;
    }
    input {
      width: 100%;
      padding: 0.7 rem;
    }
  }
`;

const PrevComment = styled.div`
  display: flex;
  background-color: #fff;
  width: 70%;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 4rem;
  padding: 0.4rem;

  img {
    width: 50px;
    border-radius: 50%;
  }

  span.dt {
    font-size: 0.8rem;
    color: #c0c1c2;
    padding-right: 3rem;
  }

  p.icn {
    font-size: 0.9rem;
    margin-top: 1rem;
    display: flex;
    margin-left: 1rem;

    & > * {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    width: 90%;
    margin: 1rem;
  }
`;

const ReadNews = () => {
  const location = useLocation();
  const newsId = location.pathname.split("/")[2];
  const [cmmt, setCmmt] = useState("");
  const userId =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;

  const url = "/news/" + newsId;
  const url1 = "/newsComment/all/" + newsId;

  const [tdata, setData] = useState([]);
  const [cdata, setComments] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
        const comm = await userRequest.get(url1);
        setComments(comm.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [url, url1]);

  const handleClick = (e) => {
    e.preventDefault();
    publicRequest
      .post("/newsComment", { userId, newsId, comment: cmmt })
      .then((res) => {
        setCmmt("");
      });
  };

  return (
    <InfoDiv>
      <Container>
        <div>
          <h1>{tdata.header}</h1>
          <p className="dateP">
            {moment(tdata.createdAt).format("MMMM DD YYYY | hh:mm a")}
          </p>
        </div>
        <img src={tdata.img} alt="News" />
        <p>{tdata.message}</p>
      </Container>
      <Comment>
        <h4>
          {tdata.newsComments ? tdata.newsComments.length + " " : "No "}
          Comment
        </h4>
        <div>
          <form>
            <label htmlFor="comment">Comment:</label>
            {/* <textarea
            id="comment"
            placeholder="Comment"
            name="comment"
            rows="2"
          ></textarea> */}
            <input
              type="hidden"
              name="newsId"
              value={newsId}
              required
              readOnly
            />
            <input
              type="hidden"
              name="userId"
              value={userId}
              required
              readOnly
            />
            {/* <input
              type="text"
              id="comment"
              placeholder="Comment"
              name="comment"
              value={cmmt}
              disabled={userId ? false : true}
              onChange={(e) => setCmmt(e.target.value)}
            /> */}
            <textarea
              name="comment"
              id="comment"
              placeholder="Comment"
              maxLength="280"
              value={cmmt}
              disabled={userId ? false : true}
              onChange={(e) => setCmmt(e.target.value)}
            ></textarea>
            {userId ? (
              <button onClick={handleClick} className="btn" primary="true">
                Share
              </button>
            ) : (
              <button to={"/login"} className="btn" primary="true">
                Login
              </button>
            )}
          </form>
        </div>
      </Comment>

      {cdata
        .reverse()
        .slice(0, 10)
        .map((comment, index) => (
          <PrevComment key={index}>
            <img
              src={
                comment.userId.img
                  ? comment.userId.img
                  : require("../../images/staff.png")
              }
              alt=""
            />
            <div className="prevComments">
              <h4>
                {comment.userId.name}{" "}
                <span className="dt">
                  {moment(comment.createdAt).fromNow(true)}
                </span>
              </h4>
              <p>{comment.comment}</p>
              {userId && (
                <p className="icn">
                  <AiOutlineLike />
                  <AiOutlineDislike />
                  <Link>Reply</Link>
                </p>
              )}
            </div>
          </PrevComment>
        ))}
    </InfoDiv>
  );
};

export default ReadNews;
