import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Sponsors from "../components/sponsors/Sponsors";
import SuggestionForm from "../components/suggestion/Suggestion";

const Suggestion = () => {
  return (
    <>
      <SuggestionForm />
      <Sponsors />
      <Footer />
      <Footmark />
    </>
  );
};

export default Suggestion;
