import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import AboutPage from "../components/about/About";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";

const About = () => {
  return (
    <>
      <AboutPage />
      <Sponsors />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default About;
