import React, { useEffect, useState } from "react";
import styled from "styled-components";
import bg from "../../images/suggestion-box.png";
import { userRequest } from "../../requestMethod";
// import { Button } from "../Button";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 50%;
  background-color: #fff;

  img {
    width: 50%;
  }

  h2 {
    color: var(--fifth-color);
    font-weight: 500;
    font-size: 4rem;
    text-align: center;
  }

  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 100%;
    padding-top: 1rem;

    h2 {
      font-size: 2rem;
    }

    img.bgImg {
      image-size: cover;
      image-position: top;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 35%;
  padding: 1rem;
  min-height: 100vh;

  p.header {
    text-align: center;
    font-size: 1.5rem;
  }

  h1 {
    font-weight: 400;
    font-size: 2rem;
    margin: 0.3rem auto;
  }
  .message {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(155, 50%, 75%);
    margin: 0.5rem;
    background-color: hsl(125, 90%, 75%);
    font-weight: 500;
  }

  .axios_error {
    display: flex;
    color: #fff;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(9, 98%, 51%);
    margin: 0.5rem;
    background-color: hsl(9, 82%, 55%);
    font-weight: 500;
  }

  form {
    width: 100%;
    padding: 0.5rem;
  }
  .btn-grp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input.btn {
    width: 70%;
    margin: 0.5rem 0;
    border: 0;
    padding: 1rem 3rem;
    border-radius: 0.3rem;
    background-color: var(--first-color);
    color: #fff;
  }

  button.cls {
    width: 100%;
    margin: 0.2rem 0;
    padding: 1rem 3rem;
    border-radius: 0.3rem;
    background-color: #ccc;
  }

  .agree {
    color: var(--fifth-color);
  }

  .chk {
    margin: 0.5rem 1rem;
  }

  .terms {
    font-weight: 500;
    color: #000;
    margin-left: 0.3rem;
  }

  .signup {
    color: var(--first-color);
    margin-left: 0.5rem;
    font-weight: 400;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    min-height: 100%;
    display: grid;

    form {
      width: 90%;
      padding: 1rem 0;
    }

    button {
      width: 80%;
    }

    button.cls {
      width: 70%;
    }

    p.no-account {
      text-align: center;
      padding-bottom: 2rem;
    }

    p.header {
      font-size: 1rem;
      color: var(--fourth-color);
    }
  }
`;

const FormGroup = styled.div`
  width: 90%;
  margin: 0.5rem 1rem;

  label {
    display: block;
    margin: 1rem 0 0.2rem 0;
    color: var(--first-color);
    font-weight: 500;
    letter-spacing: 0.4rem;
    font-size: 1rem;
  }

  textarea {
    width: 100%;
    padding: 0.8rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    outline: none;
  }

  input {
    padding: 0.8rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    outline: none;
  }

  select {
    padding: 0.8rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    outline: none;
  }

  .suggestions {
    list-style: none;
    margin: 0;
    padding: 0;
    border-top: 1px solid #d4d4d4;
    background-color: #f7f7f7;
    border-radius: 0 0 10px 10px;
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

function SuggestionForm() {
  const userId =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;
  const url = "/suggestion";
  const [lga, setLga] = useState([]);
  const [ward, setWard] = useState([]);
  const [region, setRegion] = useState("");

  const [data, setData] = useState({
    userId: userId,
    lga: "",
    ward: "",
    community: "",
    title: "",
    comment: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  function handleSubmit(e) {
    e.preventDefault();
    console.log(data);
    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit && data.title !== "") {
      userRequest.post(url, data).then((res) => {
        setData({
          lga: "",
          ward: "",
          community: "",
          title: "",
          comment: "",
        });
      });
    }
    const loadLga = async () => {
      try {
        const res = await userRequest.get("/lgas");
        // console.log(res.data);
        setLga(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    const loadWard = async () => {
      try {
        const res = await userRequest.get(`/wards/lga/${region}`);
        // console.log(res.data);
        setWard(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    loadLga();
    region !== "" && loadWard();
  }, [formErrors, isSubmit, data, region]);

  const validate = (values) => {
    const errors = {};
    if (!values.lga) {
      errors.lga = "L.G.A is required!";
    }
    if (!values.ward) {
      errors.ward = "Ward is required!";
    }
    if (!values.title) {
      errors.title = "title is required!";
    }
    if (!values.comment) {
      errors.comment = "comment is required!";
    }

    return errors;
  };

  const handleSelected = (event) => {
    const newData = { ...data };
    newData[event.target.name] = event.target.value;
    setData(newData);
    setRegion(event.target.value);
  };

  const handleSelect = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  return (
    <Container>
      <ImgContainer>
        <h2>Feel free to drop your suggestions.</h2>
        <img className="bgImg" src={bg} alt="" />
      </ImgContainer>
      <FormContainer>
        <p className="header">
          Do you have a suggestion or project idea in your community? We would
          like to hear from you! Your opinion counts.
        </p>
        {Object.keys(formErrors).length === 0 && isSubmit ? (
          <div className="ui message success">
            Suggestion was sent successfully. Thank you for your contribution.
          </div>
        ) : (
          ""
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          {/* <input type="hidden" name="userId" value={userId} required /> */}
          <FormGroup>
            <label htmlFor="name" className="form-control">
              L.G.A
            </label>
            <select
              required
              name="lga"
              className={formErrors.lga ? "err" : isSubmit ? "crt" : undefined}
              onChange={handleSelected}
            >
              <option value="">--</option>
              {lga.map((lg, i) => (
                <option value={lg._id} key={i}>
                  {lg.name}
                </option>
              ))}
            </select>
          </FormGroup>
          <FormGroup>
            <label htmlFor="name" className="form-control">
              Ward
            </label>
            <select
              required
              name="ward"
              className={formErrors.ward ? "err" : isSubmit ? "crt" : undefined}
              onChange={handleSelect}
            >
              <option value="">--</option>
              {ward.length !== 0 &&
                ward.map((wd, i) => (
                  <option value={wd.name} key={i}>
                    {wd.name}
                  </option>
                ))}
            </select>
          </FormGroup>
          <FormGroup>
            <label htmlFor="username" className="form-control">
              Community
            </label>
            <input
              type="text"
              placeholder="Community name"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.community ? "err" : isSubmit ? "crt" : undefined
              }
              name="community"
              value={data.community}
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="username" className="form-control">
              Title
            </label>
            <input
              type="text"
              placeholder="Title of suggestion"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.title ? "err" : isSubmit ? "crt" : undefined
              }
              name="title"
              value={data.title}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="comment" className="form-control">
              Comment
            </label>
            <textarea
              rows="8"
              placeholder="Tell us your about suggestion"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.comment ? "err" : isSubmit ? "crt" : undefined
              }
              name="comment"
              value={data.comment}
              required
            ></textarea>
          </FormGroup>
          <FormGroup>
            <div className="btn-grp">
              <input type="submit" className="btn btn-primary" value="Send" />
            </div>
          </FormGroup>
        </form>
      </FormContainer>
    </Container>
  );
}

export default SuggestionForm;
