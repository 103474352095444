import React from "react";
import styled from "styled-components";
import logo from "../../images/banner-planning-commision.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  line-height: 2rem;

  img {
    padding: 1rem;
    width: 100%;
  }

  h1 {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 3rem;
    line-height: 3rem;
    color: #000;
  }

  h2 {
    color: var(--dark-bg);
  }

  ol.alph li {
    list-style-type: alpha;
    color: var(--first-color);
  }

  ul.dots li {
    list-style-type: circle;
  }

  ol {
    font-weight: 500;
    margin-bottom: 3rem;
    display: inline-block;

    li {
      list-style-type: decimal;
    }
  }

  ul {
    font-weight: 300;
    color: var(--fifth-color);

    li {
      list-style-type: square;
    }
  }

  p {
    margin-bottom: 2rem;
  }

  p.note {
    color: var(--first-color);
    clear: both;
  }

  @media screen and (max-width: 480px) {
    width: 90%;

    img {
      margin: 2px;
      display: inline-block;
      width: 100%;
    }

    h1 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
`;

const About = () => {
  return (
    <InfoDiv>
      <Container>
        <h1>Adamawa State Planning Commission</h1>
        <img src={logo} alt="" />
        <p>
          Adamawa State Planning Commission was established by virtue of the
          Edict No. 1 of 1999 and became effective on 24th March, 1999
          (gazetted). The primary objective of the ASPC is to strengthen its
          overall capacity and take centre stage as the{" "}
          <strong>‘brainbox and champion’</strong>
          of <strong>growth</strong> and <strong>development</strong> in the
          State in line with Government’s vision, hopes and aspirations of
          citizens of Adamawa State. This necessitated the development of this
          CP, which the Government is committed to implementing.
        </p>
        <p>
          The Commission’s summarized <strong>Mandates</strong> by its
          established law include:
        </p>
        <ul className="dots">
          <li>
            Advise Government on all development and governance issues in the
            State,
          </li>
          <li>
            Promote peace, unity, social justice and human welfare in the State,
          </li>
          <li>Ensure prudence in the management of State resources, and </li>
          <li>
            Enforce compliance with Government policy directives and guidelines
            on programmes and budgets.
          </li>
        </ul>
        <br />
        {/* <p className="note">FUNCTIONS OF THE COMMISSION:</p> */}
        <p>The summarized functions of the ASPC include:</p>
        <ol className="alph">
          <li>
            Provide Policy advice on development plan of the state to the
            Governor,
          </li>
          <li>
            Formulate and prepare long term, medium-term and short-term
            development plan for the entire state,
          </li>
          <li>
            Prepare and control Capital budget and set broad guidelines for the
            recurrent Budget.
          </li>
          <li>
            To Monitor projects and progress relating to plan implementation.
          </li>
          <li>
            To register and manage all activities of foreign and internal
            economic co-operations including aids and technical assistance.
          </li>
          <li>
            Organize the identification of both abandoned and ongoing projects
            in the State and to provide information as to; cause of abandonment,
            prioritization and find out level of completion so as to advice
            Government accordingly.
          </li>
          <li>
            Formulation and evaluation of manpower development policies and
            strategies of the State.
          </li>
          <li>Establish a state data bank.</li>
          <li>
            Hold periodic meetings with similar bodies as may be set up by the
            state and local governments.
          </li>
        </ol>
        <p>
          The ASPC has the Mission of championing the State’s development
          initiatives through sound advice to government; promoting unity,
          social justice and human welfare; and ensuring prudence in the
          management of State resources.
        </p>
        <p>
          This can be achievable through a meaningful attention given to factors
          largely external affecting the performance of the Commission. Such
          factors include; political, economic, social, Technological, Legal,
          Environmental and security. The use of SWOT analysis is a useful tool
          for understanding the “big picture” of the planning environment. This
          enables the Commission to build on the identified strengths, reduce
          the weaknesses, take advantage of the opportunities and minimize the
          threats as it looks at the internal and external dynamics of the
          Commission that could support or hinder effective planning and
          implementation of strategies to achieve planned results.
        </p>
      </Container>
    </InfoDiv>
  );
};

export default About;
