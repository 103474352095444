import React, { useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 4rem 0;
  margin: 2rem auto;
  justify-content: center;
  align-items: center;
  background-color: var(--light-bg);
  flex-direction: column;

  .success {
    color: #000;
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  i {
    display: inline-block;
    color: red;
    margin-top: 1rem;
  }

  @media screen and (max-width: 780px) {
    padding: 2rem 0;
    margin: 1rem auto;
  }
`;

const Topic = styled.h1`
  color: var(--first-color);
  text-align: center;
  margin-bottom: 1rem;
`;

const InputContainer = styled.div`
  width: 50%;
  height: 60px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  border: 1px solid lightgray;
  border-radius: 10px;

  @media screen and (max-width: 780px) {
    width: 80%;
  }
`;

const Input = styled.input`
  border: none;
  flex: 8;
  padding: 1rem;
  border-radius: 10px;
  outline: none;
`;

const Button = styled.button`
  flex: 1;
  border: none;
  background-color: var(--white-color);
  color: var(--first-text);

  @media screen and (max-width: 780px) {
    flex: 2;
  }
`;

const Search = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    search: "",
  });

  //   const url = `/project?q=${data.search}`;
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  //   const keys = ["title", "category", "lga", "contractor"];
  //   const search = (fdata) => {
  //     return fdata.filter((item) =>
  //       keys.some(
  //         (key) => item[key] && item[key].toLowerCase().includes(data.search)
  //       )
  //     );
  //   };

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      data.search !== ""
    ) {
      navigate(`/projects/?q=${data.search.toLowerCase()}`);
    }
  }, [formErrors, isSubmit, data, navigate]);

  const validate = (values) => {
    const errors = {};

    if (!values.search) {
      errors.search = "You did not enter a search value.";
    }
    return errors;
  };

  return (
    <Container>
      <Topic>Search Projects in Adamawa State</Topic>
      {/* <Intro>Search Project in Adamawa State</Intro> */}
      <InputContainer>
        <Input
          name="search"
          onChange={(e) => handleChange(e)}
          placeholder="Search for Project, sector, L.G.A or Contractor"
          value={data.search}
          autoComplete="off"
        />
        <Button type="submit" onClick={(e) => handleSubmit(e)}>
          <FaSearch size={20} />
        </Button>
      </InputContainer>
      <i>{formErrors.search}</i>
    </Container>
  );
};

export default Search;
