import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaFacebook, FaTwitter, FaYoutube } from "react-icons/fa";

const FooterDiv = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  justify-content: space-between;
  padding: 4rem;
  width: 100%;
  color: #000;
  background: var(--light-bg);
  line-height: 2rem;

  @media screen and (min-width: 300px) {
    grid-template-columns: 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 850px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 1.5rem;
  }

  @media screen and (min-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 4rem;
  }
`;
const First = styled.div`
  padding 1rem 0;

  h2 {
      color: var(--secondary-color);
  }

//   img {
//       width: 100px;
//   }
`;
const Second = styled.div``;
const Third = styled.div`
  display: flex;
  justify-content: center;

  ul {
    margin: 0.5rem auto;
    line-height: 2rem;
  }

  li > * {
    text-decoration: none;
    color: var(--dark-bg-txt);
  }

  li > *:hover {
    color: var(--primary-color);
  }
`;
const Fourth = styled.div``;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.8rem;

  & > * {
    margin: 1rem 2rem;
    cursor: pointer;
  }

  & > *:hover {
    background: #fff;
  }
`;

const Footer = () => {
  return (
    <div>
      <FooterDiv>
        <First>
          {/* <img src="https://i.ibb.co/sHZz13b/logo.png"/> */}
          <h2 style={{ marginBottom: "1rem", fontSize: "1.5rem" }}>
            Adamawa State
          </h2>
          <p>Adamawa Planning Commision, State Secretariat Complex,</p>
          <p>Yola, Adamawa State.</p>
          <p>Nigeria.</p>
          <h4 style={{ color: "#ff893b" }}>Phone</h4>
          <p>+234-7062259055, +234-7045955257</p>
          <h4 style={{ color: "#ff893b" }}>Email</h4>
          <p>info@admehub.ng</p>
          <p>adamawaeyesandears@gmail.com</p>
        </First>
        <Second>
          <h4
            style={{ color: "black", marginBottom: "1rem", fontSize: "1.5rem" }}
          >
            About Us
          </h4>
          <p>
            This project is part of Oxfam in Nigeria’s support to Adamawa state
            government with Support from the European Union to develop and
            institutionalize a citizens driven State budget and Monitoring &
            Evaluation Management Information system
          </p>
          <SocialIcons>
            <FaFacebook style={{ color: "#009" }} />
            <FaTwitter style={{ color: "#009" }} />
            <FaYoutube style={{ color: "#900" }} />
          </SocialIcons>
        </Second>
        <Third>
          <ul>
            <h4>Nav</h4>
            <Link to="/">
              <li>Home</li>
            </Link>
            <Link to="/about">
              <li>About Us</li>
            </Link>
            <Link to="/projects">
              <li>State Projects</li>
            </Link>
            <Link to="/partners_projects">
              <li>Partners Project</li>
            </Link>
            <Link to="/budget">
              <li>Budgets</li>
            </Link>
          </ul>
          <ul>
            <h4>Quick Link</h4>
            <Link to="/dashboard">
              <li>Dashboard</li>
            </Link>
            <Link to="/contact">
              <li> Contact Us</li>
            </Link>
            <Link to="/news">
              <li> News</li>
            </Link>
            <Link to="/register">
              <li> Register</li>
            </Link>
            <Link to="/login">
              <li> Login</li>
            </Link>
          </ul>
        </Third>
        <Fourth>
          <p></p>
        </Fourth>
      </FooterDiv>
    </div>
  );
};

export default Footer;
