import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ModalDiv = styled.div`
  position: fixed;
  z-index: 999;
  width: 100px;
  background: var(--first-color);
  display: grid;
  align-item: center;
  top: 500px;
  right: 50px;
  transition: 0.5s ease-in-out;
  tranform-origin: right;
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  top: ${({ isOpen }) => (isOpen ? "0" : "-100%")};

  ul {
    display: inline-block;

    li {
      color: var(--fourth-color);
      font-size: 1rem;
    }
  }
`;

const Modal = ({ isOpen, toggler }) => {
  return (
    <ModalDiv isOpen={isOpen} onClick={toggler}>
      <ul>
        <Link to={"/logout"}>
          <li>Logout</li>
        </Link>
      </ul>
    </ModalDiv>
  );
};

export default Modal;
