import "./dashboard.scss";
// import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
// import { useEffect, useState } from "react";
// import { publicRequest } from "../../requestMethod";

const ImplementingPartners = () => {
  //   const [mdas, setMdas] = useState([]);

  // const Random = (mx) => {
  //   var randomNumber = Math.floor(Math.random() * mx + 1);
  //   return randomNumber;
  // };

  //   useEffect(() => {
  //     const getReports = async () => {
  //       try {
  //         const res = await publicRequest.get("/project/sector");
  //         setMdas(res.data);
  //       } catch (error) {
  //         console.log(error);
  //       }
  //     };
  //     getReports();
  //   }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell className="tableCell">Tracking ID</TableCell> */}
            <TableCell className="tableCell">Partner</TableCell>
            <TableCell className="tableCell">Projects</TableCell>
            <TableCell className="tableCell">Completion</TableCell>
            <TableCell className="tableCell">Quality</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className="tableCell">GIZ(Funded by EU)</TableCell>
            <TableCell className="tableCell">126</TableCell>
            <TableCell className="tableCell">100%</TableCell>
            <TableCell className="tableCell">95%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">NURU ( by GIZ)</TableCell>
            <TableCell className="tableCell">17</TableCell>
            <TableCell className="tableCell">83%</TableCell>
            <TableCell className="tableCell">98%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">State2State</TableCell>
            <TableCell className="tableCell">27</TableCell>
            <TableCell className="tableCell">83%</TableCell>
            <TableCell className="tableCell">93%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">OXFAM (by GIZ)</TableCell>
            <TableCell className="tableCell">15</TableCell>
            <TableCell className="tableCell">90%</TableCell>
            <TableCell className="tableCell">95%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">
              ASUBEB ( Learn to Read) (by USAID)
            </TableCell>
            <TableCell className="tableCell">12</TableCell>
            <TableCell className="tableCell">89%</TableCell>
            <TableCell className="tableCell">92%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">MOH (by UNFPA)</TableCell>
            <TableCell className="tableCell">9</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">MOWA&CD ( by UNFPA)</TableCell>
            <TableCell className="tableCell">15</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">CRUDAN ( by GIZ, OXFAM)</TableCell>
            <TableCell className="tableCell">14</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">MoA ( by NG-CARES)</TableCell>
            <TableCell className="tableCell">18</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">CSDA ( by NG-CARES)</TableCell>
            <TableCell className="tableCell">11</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">PAWECCA ( by NG- CARES)</TableCell>
            <TableCell className="tableCell">18</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className="tableCell">
              International Rescue Committee
            </TableCell>
            <TableCell className="tableCell">15</TableCell>
            <TableCell className="tableCell">86%</TableCell>
            <TableCell className="tableCell">89%</TableCell>
          </TableRow>
          {/* {mdas.map((row, i) => (
            <TableRow key={i}>
              <TableCell className="tableCell">{row._id}</TableCell>
              <TableCell className="tableCell">{row.count}</TableCell>
              <TableCell className="tableCell">
                {Math.floor(row.comp / row.count)}%
              </TableCell>
              <TableCell className="tableCell">
                {Math.floor(row.count - i + 60)}%
              </TableCell>
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ImplementingPartners;
