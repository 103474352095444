import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import NewsPage from "../components/news/NewsPage";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";

const News = () => {
  return (
    <>
      <NewsPage />
      <Newsletter />
      <Sponsors />
      <Footer />
      <Footmark />
    </>
  );
};

export default News;
