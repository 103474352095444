import "./modal.scss";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { userRequest } from "../../requestMethod";

const Modal = ({ closeModal }) => {
  const location = useLocation();
  const projectId = location.pathname.split("/")[2];
  const userId =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;
  const [input, setInput] = useState({
    projectId: projectId,
    userId: userId,
    status: "",
    performance: "",
    comment: "",
  });
  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    userRequest.post("/report", input).then((res) => {
      setInput({
        projectId: projectId,
        userId: userId,
        status: "",
        performance: "",
        comment: "",
      });
    });
    console.log(input);
  };

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button onClick={() => closeModal(false)}> X </button>
        </div>
        <div className="title">
          <h1>Project Report</h1>
        </div>
        <div className="body">
          <form onSubmit={(e) => handleSubmit(e)}>
            <label htmlFor="status">Status:</label>
            <input
              type="text"
              name="status"
              onChange={handleChange}
              placeholder="Status"
            />
            <label htmlFor="performance">Performance:</label>
            <input
              type="text"
              name="performance"
              onChange={handleChange}
              placeholder="Performance"
            />
            <label htmlFor="year">Comment:</label>
            <textarea
              rows="10"
              name="comment"
              onChange={handleChange}
              placeholder="Comment"
            ></textarea>
            <button type="submit" className="modalSubmitBtn">
              Submit
            </button>
          </form>
        </div>
        <div className="footer"></div>
      </div>
    </div>
  );
};

export default Modal;
