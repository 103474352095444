import React from "react";
import styled from "styled-components";
import Adamawa from "../../images/logo.png";
import Oxfam from "../../images/oxfamLogo.png";
import Eu from "../../images/EU_LOGO.png";
// import Usaid from "../../images/usaid.png";
import Immap from "../../images/immap.png";
import Crudan from "../../images/crudan.png";
import Unfpa from "../../images/unfpa.jpg";

const Container = styled.div`
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  align-item: center;
  justify-content: center;
  background-color: #fff;
  gap: 2rem;

  img {
    height: 130px;
    margin: 0 1rem;
  }

  @media screen and (max-width: 1400px) {
    img {
      height: 120px;
      margin: 0 1rem;
    }
  }

  @media screen and (max-width: 1250px) {
    img {
      margin: 0 0.5rem;
    }
  }

  @media screen and (max-width: 500px) {
    padding: 1.5rem;
    gap: 1rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

    img {
      height: 100px;
      margin: 0 1rem;
    }
  }
`;

const Sponsors = () => {
  return (
    <>
      <Container>
        <img src={Adamawa} alt="" />
        <img src={Eu} alt="" />
        <img src={Oxfam} alt="" />
        {/* <img src={Usaid} alt="" /> */}
        <img src={Immap} alt="" />
        <img src={Crudan} alt="" />
        <img src={Unfpa} alt="" />
      </Container>
    </>
  );
};

export default Sponsors;
