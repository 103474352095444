import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";
// import Accordion from "../components/accordion/Accordion";
import ExecutiveGovernor from "../components/about/ExecutiveGovernor";

const AboutGovernor = () => {
  return (
    <>
      <ExecutiveGovernor />
      {/* <Accordion /> */}
      <Sponsors />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default AboutGovernor;
