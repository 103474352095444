export const BarData = [
  {
    country: "Demsa",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  // {
  //   country: "Fufore",
  //   notStarted: 3,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 15,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 36,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 16,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  // {
  //   country: "Ganye",
  //   notStarted: 53,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 75,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 46,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 65,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  {
    country: "Girei",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 0,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 2,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Gombi",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Guyuk",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 0,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Hong",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 2,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Jada",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 0,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 2,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Lamurde",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Madagali",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 2,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  // {
  //   country: "Maiha",
  //   notStarted: 22,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 43,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 19,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 25,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  {
    country: "Mayo-Belwa",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Michika",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 0,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Mubi-North",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Mubi-South",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 0,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Numan",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 0,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  // {
  //   country: "Shelleng",
  //   notStarted: 22,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 67,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 12,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 76,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  // {
  //   country: "Song",
  //   notStarted: 6,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 23,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 96,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 56,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  // {
  //   country: "Toungo",
  //   notStarted: 33,
  //   notStartedColor: "hsl(351, 70%, 50%)",
  //   ongoing: 65,
  //   ongoingColor: "hsl(196, 70%, 50%)",
  //   completed: 35,
  //   completedColor: "hsl(143, 70%, 50%)",
  //   abandoned: 88,
  //   abandonedColor: "hsl(293, 70%, 50%)",
  // },
  {
    country: "Yola North",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 2,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 4,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
  {
    country: "Yola South",
    notStarted: 0,
    notStartedColor: "hsl(351, 70%, 50%)",
    ongoing: 1,
    ongoingColor: "hsl(196, 70%, 50%)",
    completed: 1,
    completedColor: "hsl(143, 70%, 50%)",
    abandoned: 0,
    abandonedColor: "hsl(293, 70%, 50%)",
  },
];
