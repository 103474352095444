import {
  addNewsStart,
  addNewsSuccess,
  addNewsFailure,
  addMarkerStart,
  addMarkerSuccess,
  addMarkerFailure,
} from "./productRedux";
import {
  getUserFailure,
  getUserStart,
  getUserSuccess,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFailure,
  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
  addUserStart,
  addUserSuccess,
  addUserFailure,
  loginStart,
  loginFailure,
  loginSuccess,
  logoutStart,
} from "./userRedux";
import { publicRequest, userRequest } from "../requestMethod";

export const facebooLogin = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/facebook", user);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(loginFailure());
  }
};

export const login = async (dispatch, user) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post("/auth/login", user);
    dispatch(loginSuccess(res.data));
  } catch (error) {
    dispatch(loginFailure());
  }
};

export const logout = async (dispatch) => {
  dispatch(logoutStart());
};

export const getUsers = async (dispatch) => {
  dispatch(getUserStart());
  try {
    const res = await userRequest.get("/users");
    dispatch(getUserSuccess(res.data));
  } catch (error) {
    dispatch(getUserFailure());
  }
};

export const deleteUser = async (id, dispatch) => {
  dispatch(deleteUserStart());
  try {
    await userRequest.delete(`/users/${id}`);
    dispatch(deleteUserSuccess(id));
  } catch (error) {
    dispatch(deleteUserFailure());
  }
};

export const updateUser = async (id, user, dispatch) => {
  dispatch(updateUserStart());
  try {
    await userRequest.patch(`/users/${id}`);
    dispatch(updateUserSuccess({ id, user }));
  } catch (error) {
    dispatch(updateUserFailure());
  }
};

export const addUser = async (user, dispatch) => {
  dispatch(addUserStart());
  try {
    const res = await userRequest.post(`/auth/register`, user);
    dispatch(addUserSuccess(res.data));
  } catch (error) {
    dispatch(addUserFailure());
  }
};

export const addNews = async (news, dispatch) => {
  dispatch(addNewsStart());
  try {
    const res = await userRequest.post(`/news`, news);
    dispatch(addNewsSuccess(res.data));
  } catch (error) {
    dispatch(addNewsFailure());
  }
};

export const addMarker = async (marker, dispatch) => {
  dispatch(addMarkerStart());
  try {
    const res = await userRequest.post(`/marker`, marker);
    dispatch(addMarkerSuccess(res.data));
  } catch (error) {
    dispatch(addMarkerFailure());
  }
};
