import React from "react";

const PartnersMatrix = () => {
  return (
    <div>
      <iframe
        title="Report Section"
        width="100%"
        height="900"
        src="https://app.powerbi.com/view?r=eyJrIjoiZDkwYmZhNTUtOGRlMi00YTk3LWJkOTYtNWMxMWYyMTA3NTJhIiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default PartnersMatrix;
