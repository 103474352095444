import React, { useState, useEffect } from "react";
import "./budget.css";
import styled from "styled-components";
import { GoFileDirectory } from "react-icons/go";
import { BsFileRichtextFill } from "react-icons/bs";
import { INDEX_URL, publicRequest } from "../../requestMethod";
// import Datatable from "../datatable/BudgetTable";
// import { Link } from "react-router-dom";

const OuterContainer = styled.div`
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    padding: 0.7rem;
  }
`;

const InnerContainer = styled.div`
  width: 80%;
  h1 {
    color: var(--fourth-color);
  }
  p {
    margin: 1rem;
  }
  Link {
    margin: 0 2rem;
    color: var(--fourth-color);
  }
`;

const HeaderDiv = styled.div`
  padding: 0.5rem;
`;

const FileDiv = styled.div`
  border: 2px solid var(--border-color);
  border-radius: 0.5rem;
  margin-top: 2rem;

  div.topDiv {
    padding: 0.7rem;
    background-color: var(--text-color-light);

    h3 {
      color: var(--fifth-color);
    }
  }
  div.bottomDiv {
    padding: 1rem;

    button.first {
      margin-left: 1rem;
      padding: 1rem;
      background-color: var(--first-color);
      border-radius: 0.5rem;
      color: white;
    }

    button.second {
      margin-left: 1rem;
      padding: 1rem;
      background-color: var(--fourth-color);
      border-radius: 0.5rem;
      color: white;
    }
  }
`;

const StateBudget = () => {
  const url = "/budgetFile";

  const [tdata, setData] = useState([]);

  function handleClick(f) {
    window.open(
      `https://docs.google.com/gview?url=${INDEX_URL}/${f.target.value}&embedded=true`,
      "_blank"
    );
    // console.log(f.target.value);
  }

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, []);
  return (
    <OuterContainer>
      <InnerContainer>
        <HeaderDiv>
          <h1>
            <GoFileDirectory /> Adamawa State Budget Documents
          </h1>
          <p>
            A budget is a financial document used to project future income and
            expenses of any organization or government institution. This section
            contains all the various document of the Adamawa State Budget as
            aprroved or appropriated over the years.
          </p>
        </HeaderDiv>
        {tdata.map((item) => (
          <FileDiv key={item._id}>
            <div className="topDiv">
              <h3>
                <BsFileRichtextFill color="black" /> {item.fiscal} Budget
                Document
              </h3>
            </div>
            <div className="bottomDiv">
              {item.docs
                .filter((dc) => dc.fileType === 1)
                .map((doc, i) => (
                  <button
                    key={i}
                    className={i % 2 === 0 ? "first" : "second"}
                    onClick={handleClick}
                    value={doc.url}
                  >
                    {doc.title}
                  </button>
                ))}
            </div>
          </FileDiv>
        ))}
      </InnerContainer>
    </OuterContainer>
  );
};

export default StateBudget;
