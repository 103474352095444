import ImageZero from "../images/his-excellency.jpg";
import ImageOne from "../images/fly-over.jpg";
import ImageTwo from "../images/housing.jpg";
import ImageThree from "../images/banner-planning-commision.jpg";
import ImageFour from "../images/slide/health.jpeg";
import ImageFive from "../images/slide/security.jpeg";
import ImageSix from "../images/slide/wash.jpeg";
import ImageSeven from "../images/slide/education.jpg";
import ImageEight from "../images/slide/transportations.jpg";

export const sliderItems = [
  {
    id: 0,
    img: ImageZero,
    title: "SUMMER SALE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "f5fafd",
  },
  {
    id: 1,
    img: ImageOne,
    title: "SUMMER SALE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "f5fafd",
  },
  {
    id: 2,
    img: ImageTwo,
    title: "AUTUMN COLLECTION",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fcf1ed",
  },
  {
    id: 3,
    img: ImageThree,
    title: "LOUNGEWEAR LOVE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fbf0f4",
  },
  {
    id: 4,
    img: ImageFour,
    title: "LOUNGEWEAR LOVE",
    desc: "DON'T COMPROMISE ON STYLE! GET FLAT 30% OFF FOR NEW ARRIVALS.",
    bg: "fbf0f4",
  },
];

export const SliderData = [
  {
    title:
      " welcome to Adamawa state technological monitoring hub citizens' eyes and ears",
    price: "",
    path: "/report",
    label: "Submit Report",
    image: ImageZero,
    alt: "",
  },
  {
    title: "infrastructure",
    price: "",
    path: "/report",
    label: "Submit Report",
    image: ImageOne,
    alt: "",
  },
  {
    title: "Health Care",
    price: "",
    path: "/register",
    label: "Register",
    image: ImageFour,
    alt: "",
  },
  {
    title: "Education",
    price: "",
    path: "/register",
    label: "Register",
    image: ImageSeven,
    alt: "",
  },
  {
    title: "Security",
    price: "",
    path: "/register",
    label: "Register",
    image: ImageFive,
    alt: "",
  },
  {
    title: "Water Sanitation and Hygiene",
    price: "",
    path: "/register",
    label: "Register",
    image: ImageSix,
    alt: "",
  },
  {
    title: "Rural Urban Transportation",
    price: "",
    path: "/register",
    label: "Register",
    image: ImageEight,
    alt: "",
  },
];

// export const SliderDataTwo = [
//   {
//     title: "Report as a Security Personnel or a Civil Service Member",
//     price: "#25,000.00",
//     path: "/",
//     label: "Book Now",
//     image: ImageFive,
//     alt: "Luxury Hotel",
//   },
//   {
//     title: "Luxury Hotel in San Jose, Costa Rica",
//     price: "#15,000.00",
//     path: "/",
//     label: "Book Now",
//     image: ImageSix,
//     alt: "Luxury Hotel",
//   },
//   {
//     title: "Luxury Double Delux Suite for you and your family",
//     price: "#35,000.00",
//     path: "/",
//     label: "Book Now",
//     image: ImageSeven,
//     alt: "Luxury Hotel",
//   },
// ];
