import React, { useEffect } from "react";
import Footmark from "../components/footmark/Footmark";
import LoginForm from "../components/login/Login";

function Login(props) {
  useEffect(() => {
    props.funcNav(false);
  }, [props]);
  return (
    <div>
      <LoginForm />
      <Footmark />
    </div>
  );
}

export default Login;
