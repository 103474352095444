import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  line-height: 2rem;

  p {
    color: #000;
  }

  @media screen and (max-width: 480px) {
    width: 90%;
  }
`;

const Topic = styled.h1`
  color: var(--fourth-color);
  text-align: center;
  margin-bottom: 1rem;
`;

function Welcome() {
  return (
    <Container>
      <Topic>Welcome to Adamawa State</Topic>
      <p>
        Adamawa State (also known as the Land of beauty) was created on 27th
        August 1991 alongside Taraba State from defunct Gongola State with Yola
        as it’s Capital. it is located in the North-East of Nigeria. It seats on
        a land area of 36, 917 square kilometers making it one the largest
        States in Nigeria placing it at 8th just behind Zamfara at 7th. It is
        bordered to the Northwest by Borno State, Gombe State to the West and
        Taraba State to the Southwest. It also shares International borders with
        Cameroon to the East. The State has 3 Senatorial Districts, 8 Federal
        Constituencies and 21 Local Government Areas
      </p>
    </Container>
  );
}

export default Welcome;
