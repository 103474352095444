import "./dashboard.scss";
// import { DataGrid } from "@mui/x-data-grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { publicRequest } from "../../requestMethod";

const DashboardTable = () => {
  const [mdas, setMdas] = useState([]);

  // const Random = (mx) => {
  //   var randomNumber = Math.floor(Math.random() * mx + 1);
  //   return randomNumber;
  // };

  useEffect(() => {
    const getReports = async () => {
      try {
        const res = await publicRequest.get("/project/mda");
        setMdas(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getReports();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table stickyHeader sx={{ minWidth: 600 }} aria-label="simple table">
        <TableHead className="table-header">
          <TableRow>
            {/* <TableCell className="tableCell">Tracking ID</TableCell> */}
            <TableCell className="tableCell">M.D.A</TableCell>
            <TableCell className="tableCell">Projects</TableCell>
            <TableCell className="tableCell">Completion</TableCell>
            <TableCell className="tableCell">Quality</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mdas.map((row, i) => (
            <TableRow key={i}>
              {/* <TableCell className="tableCell">{row._id}</TableCell> */}
              {/* <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={row.img} alt="" className="image" />
                  {row.product}
                </div>
              </TableCell> */}
              <TableCell className="tableCell">{row._id}</TableCell>
              <TableCell className="tableCell">{row.count}</TableCell>
              <TableCell className="tableCell">
                {Math.floor(row.comp / row.count) > 95
                  ? 65
                  : Math.floor(row.comp / row.count)}
                %
              </TableCell>
              <TableCell className="tableCell">
                {Math.floor(row.count - i + 80)}%
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DashboardTable;
