import React from "react";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../../images/Map-of-Adamawa-State.jpg";

const InfoDiv = styled.section`
  width: 100%;
  height: 100%;
  padding: 0rem;
  margin: 1rem auto;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
`;

const Container = styled.div`
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 2rem auto;
  line-height: 2rem;

  img {
    float: left;
    padding: 1rem;
    width: 40%;
  }

  h1 {
    text-align: center;
    font-size: 3rem;
    line-height: 3rem;
    color: #000;
  }

  h2.sck {
    text-align: center;
    font-size: 2.5rem;
    color: #900;
    margin-bottom: 3rem;
  }

  h2 {
    color: var(--dark-bg);
  }

  ol {
    font-weight: 500;
    margin-bottom: 3rem;
    display: inline-block;

    li {
      list-style-type: decimal;
    }
  }

  ul {
    font-weight: 300;
    color: var(--first-color);

    li {
      list-style-type: square;
    }
  }

  p {
    margin-bottom: 2rem;
  }

  p.note {
    color: var(--first-color);
    clear: both;
  }

  h2.note {
    color: var(--fifth-color);
    font-size: 1rem;
    clear: both;
  }

  @media screen and (max-width: 480px) {
    width: 90%;

    img {
      margin: 2px;
      display: inline-block;
      width: 100%;
    }

    h1 {
      font-size: 1.5rem;
    }

    h2.sck {
      font-size: 1.1rem;
      margin-bottom: 0.5rem;
    }

    h2 {
      font-size: 1.5rem;
    }
  }
`;

const About = () => {
  return (
    <InfoDiv>
      <Container>
        <h1>ADAMAWA STATE CITIZENS' EYES AND EARS</h1>
        <h2 className="sck">(Public Expenditure Tracking System(PETS))</h2>
        <img src={logo} alt="" />
        <p>
          The Adamawa State Technological Monitoring Hub (CITIZENS' EYES AND
          EARS) is an independent M&E unit situated in the Service Delivery &
          Improvement (SDI) department of the Adamawa State Planning Commission
          (ASPC) that performs evidence based assessments on projects and
          programmes of Government and alignment to the state development plan.
          As part of the commitment of Adamawa State Government to Open
          Government Partnership (OGP) principles, the Unit is also responsible
          for harvesting feedback from citizens on government projects and
          programmes as it relates to result delivery and value for money to
          inform improved fiscal planning for sustainable development.
        </p>
        <h2 className="note">
          Importance of the Technological M & E project to Adamawa State
          Citizens:
        </h2>
        <ol className="blt">
          <li>
            Policy makers are informed on the actual or likely performance
            trends of budgetary investments of the State’s annual Budget
            Framework.
          </li>
          <li>
            Planning Commission provides knowledge management and feedback to
            MDAs on progress of their implementation as a learning mechanism.
          </li>
          <li>
            Policy makers have a Single Global view of project and programme
            implementation as it relates to the goals and results highlighted in
            the State Development Plan (SDP) with emphasis on completion,
            quality and results achieved within the overall visioning framework
          </li>
          <li>
            Baseline data from Adamawa Bureau Statistics aligns with actionable
            output data from the M&E Department to help in decision making and
            planning outcomes.
          </li>
        </ol>
        <h2 className="note">OPEN GOVERNMENT PARTNESHIP</h2>
        <p>
          The OGP is an international multi-stakeholder initiative focused on
          improving transparency, accountability, citizen participation, and
          responsiveness to citizens through technology and innovation. It
          brings together government and civil society champions of reforms that
          recognize that governments are more likely to be more effective and
          credible when they open governance to public input and oversight. At
          the national level, OGP introduces a domestic policy mechanism through
          which the government and civil society can have an ongoing dialogue.
          At the international level, it provides a global platform to connect,
          empower, and support domestic reformers committed to transforming
          governments and societies through openness.
        </p>
        <p>
          The OGP was formally launched in 2011 when the eight (8) founding
          governments (Brazil, Indonesia, Mexico, Norway, the Philippines, South
          Africa, the United Kingdom, and the United States) endorsed the Open
          Government Declaration and announced their country action plans. The
          uniqueness of the OGP process lies in the implementation of the
          National Action Plan, which provides an organizing framework for
          international networking and incentives.
        </p>
        <p>
          The Adamawa State Open Government Partnership (OGP) revolves around
          key thematic areas and commitments such as
        </p>
        <ul>
          <li>Fiscal Transparency</li>
          <li>Citizen Engagement</li>
          <li>Anti-Corruption</li>
          <li>Social Inclusion</li>
          <li>Improve Service Delivery </li>
        </ul>
        <h3>Commitments include:</h3>
        <ul>
          <li>Ease of Doing Business</li>
          <li>Open Contracting</li>
          <li>Technology Citizen Feedback</li>
        </ul>
        <p>
          Thus: MDA in which the thematic areas are housed includes the Ministry
          of Finance, Ministry of Budget & Economic Planning/Adamawa State
          Planning Commission, Ministry of Justice, Ministry of Women Affairs &
          Social Development, and Ministry of Agriculture. The inauguration of
          the second phase of the state steering committee reflects the
          continued dedication to fostering an open government culture. The
          journey so far has witnessed notable achievements, positioning Adamawa
          State as a leader in promoting good governance. One of the notable
          achievements of the Adamawa State OGP is the successful development of
          the Girei Community Development Plan. This initiative has played a
          pivotal role in enhancing the budgetary process by introducing
          transparency and accountability into the budget system. The
          integration of community perspectives has not only improved the
          accuracy of budget allocations but has also fostered a sense of
          ownership among the citizens. Also, the OGP in Adamawa State has
          actively pursued citizen engagement initiatives to ensure the
          inclusion of public input in the state budgeting process. This
          commitment to participatory governance has resulted in the development
          of a Citizen Budget, simplifying the budgetary information for the
          common man. By involving citizens in decision-making processes, the
          state has taken a significant step towards responsive and
          people-oriented governance. However, the introduction of the
          Technological Monitoring HUB, also known as Citizen Eyes and Ears, is
          a groundbreaking initiative in public expenditure tracking. This
          system empowers citizens to monitor government activities and report
          any irregularities, contributing to enhanced accountability. By
          leveraging technology, Adamawa State has created a robust mechanism
          for citizens to actively participate in governance and hold
          authorities accountable. This tangible outcome has been reflected in
          the state's improved ranking in the Global Transparency and
          Accountability Ranking. Recent evidence places Adamawa State in the
          impressive third position with a 63% score. This ranking underscores
          the state's commitment to transparency, effective governance, and
          responsiveness to the needs and expectations of its citizens. To this
          end, as Adamawa State continues its OGP journey, the achievements thus
          far serve as a testament to the positive impact of collaborative
          efforts between the government and its citizens in building a more
          accountable and responsive state.
        </p>
        {/* <h3>COMPONENTS OF THE PROJECT</h3>
        <p>The project has four(4) distinct components namely:</p>
        <ul>
          <li>
            <Link to="/citizen_engagement_framework">
              Citizen Engagement Policy & Framework
            </Link>
          </li>
          <li>
            <Link to="/citizens_budget">Citizens Budget</Link>
          </li>
          <li>
            <Link to="/maps">Geo-tag Coordinates</Link>
          </li>
          <li>
            <Link to="/m&e_framework">Monitoring and Evaluation Framework</Link>
          </li>
        </ul> */}
      </Container>
    </InfoDiv>
  );
};

export default About;
