import React, { useEffect } from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Latest from "../components/news/Latest";
import Newsletter from "../components/newsletter/Newsletter";
import Search from "../components/search/Search";
import Hero from "../components/slider/Hero";
import Sponsors from "../components/sponsors/Sponsors";
import Welcome from "../components/welcome/Welcome";
import { SliderData } from "../data/SliderData";

const Home = (props) => {
  useEffect(() => {
    props.funcNav(true);
  }, [props]);
  return (
    <>
      <Hero slides={SliderData} />
      <Welcome />
      <Search />
      <Latest />
      <Newsletter />
      <Sponsors />
      <Footer />
      <Footmark />
    </>
  );
};

export default Home;
