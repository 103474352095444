import React from "react";
import GrantsComp from "../components/grants/Grants";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";

const Grants = () => {
  return (
    <>
      <GrantsComp />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default Grants;
