import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Monitoring from "../components/monitoring/Monitoring";
import Newsletter from "../components/newsletter/Newsletter";

const Monitor = () => {
  return (
    <>
      <Monitoring />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default Monitor;
