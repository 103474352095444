import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { IconContext } from "react-icons";
import { FiPlus, FiMinus } from "react-icons/fi";
import { publicRequest } from "../../requestMethod";

const AccordionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 2rem;
  background: #fff;

  h1.head {
    margin-bottom: 1rem;
    color: var(--fourth-color);
  }
`;

const Container = styled.div`
  width: 100%;
  box-shadow: 2px 10px 35px 1px rgba(153, 153, 153, 0.3);
`;

const Wrap = styled.div`
  background: var(--bg-light);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  text-align: center;
  cursor: pointer;

  h1 {
    padding: 2rem;
    font-size: 1.5rem;
  }

  span {
    margin-right: 1.5rem;
  }
`;

const Dropdown = styled.div`
  background: var(--bg-light);
  color: var(--first-color);
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #00ffb9;
  border-top: 1px solid #00ffb9;

  p {
    font-size: 1.5rem;
    margin-left: 1rem;
  }
`;

const Accordion = () => {
  const [clicked, setClicked] = useState(false);
  const [faq, setFaq] = useState([]);

  const toggle = (index) => {
    if (clicked === index) {
      //if clicked question is already active, then close it
      return setClicked(null);
    }

    setClicked(index);
  };

  useEffect(() => {
    const getFaq = async () => {
      try {
        const res = await publicRequest.get("/faqs/");
        setFaq(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getFaq();
  }, []);

  return (
    <IconContext.Provider value={{ color: "var(--first-color)", size: "25px" }}>
      <AccordionSection>
        <h1 className="head">Frequently Asked Questions</h1>
        <Container>
          {faq.map((item, index) => {
            return (
              <div key={index}>
                <Wrap onClick={() => toggle(index)}>
                  <h1>{item.question}</h1>
                  <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                </Wrap>
                {clicked === index ? (
                  <Dropdown>
                    <p>{item.answer}</p>
                  </Dropdown>
                ) : null}
              </div>
            );
          })}
        </Container>
      </AccordionSection>
    </IconContext.Provider>
  );
};

export default Accordion;
