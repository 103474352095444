import React from "react";
import "./footmark.css";

const Footmark = () => {
  return (
    <div className="footmark">
      <p>© Copyright 2022. All Rights Reserved.</p>
    </div>
  );
};

export default Footmark;
