import React, { useEffect, useState } from "react";
import "./latest.css";
import { publicRequest } from "../../requestMethod";
import { Link } from "react-router-dom";
import styled from "styled-components";
import moment from "moment";

const NewsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem 0 2rem;
  background-color: rgb(245, 250, 250);

  h2 {
    text-align: center;
    font-size: 2rem;
  }

  @media screen and (max-width: 780px) {
    padding: 0.5rem;
  }
`;

const Latest = () => {
  const url = "/news";

  const [tdata, setData] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setData(res.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, []);

  return (
    <NewsContainer>
      <h2>Latest News</h2>
      <div className="angry-grid">
        {tdata.slice(0, 4).map((item, index) => (
          <div id={"item-" + index} key={index}>
            <div>
              <img className="img" src={item.img} alt="news" />
              <Link to={"/news/" + item._id}>
                <h1>{item.header}</h1>
              </Link>
              <p className="date">
                {moment(item.eventDate).format("DD MMMM YYYY")}
              </p>
              <Link to={"/news/" + item._id}>
                <p className="read">Read more...</p>
              </Link>
            </div>
          </div>
        ))}
        <Link to={"/news"}>
          <p>View More</p>
        </Link>
      </div>
    </NewsContainer>
  );
};

export default Latest;
