import styled from "styled-components";
import { Link } from "react-router-dom";

export const Button = styled(Link)`
  background: ${({ primary, secondary }) =>
    primary
      ? "var(--first-color)"
      : secondary
      ? "var(--text-color)"
      : "var(--fourth-color)"};
  white-space: nowrap;
  outline: none;
  border: none;
  border-radius: 3px;
  min-width: 100px;
  max-width: ${({ secondary }) => (secondary ? "100%" : "200px")};
  cursor: pointer;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-item: center;
  padding: ${({ big }) => (big ? "14px 35px" : "12px 20px")};
  color: ${({ primary, secondary }) =>
    primary ? "#fff" : secondary ? "#000" : "#ccc"};
  font-size: ${({ big }) => (big ? "20px" : "14px")};

  &:hover {
    transform: translateY(-2px);
    background: ${({ primary, secondary }) =>
      primary ? "#0e9e69" : secondary ? "var(--text-color-dark)" : "#9e1d00"};
  }
`;
