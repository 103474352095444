import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";
import moment from "moment";

import { publicRequest, userRequest } from "../../requestMethod";
import Modal from "../modal/Modal";

const Itter = styled.div`
  button {
    padding: 0.7rem 1rem;
    height: 50%;
    background-color: var(--first-color);
    color: #fff;
    border-radius: 0.3rem;
    margin-left: 0.5rem;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Section = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-center: center;
  justify-content: center;
  margin-bottom: 2rem;
  gap: 1rem;
  width: 90%;

  h3 {
    margin: 1rem 0;
  }

  article.desc {
    p {
      font-weight: 500;
      color: #000;
    }
  }

  article.info {
    p {
      font-size: 1rem;
      font-weight: 400;
      line-height: 2rem;

      span {
        color: var(--first-color);
        font-weight: 500;
      }
    }
  }

  @media screen and (max-width: 780px) {
    grid-template-columns: 1fr;
  }
`;

const Comment = styled.div`
  width: 100%;
  display: inline-block;
  align-items: center;
  justify-content: center;

  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  h4 {
    margin: 0 4rem;
    border-bottom: 1px solid #999;
  }

  div {
    margin: 1rem 2rem;
  }

  div.btn-div {
    width: 80%;
    display: flex;
    margin-top: 0.5rem;
    align-item: center;
    justify-content: center;
  }

  label {
    font-weight: 500;
    margin: 0 1rem;
    color: #000;
  }

  input {
    width: 80%;
    padding: 1rem;
    border: 1px solid #ccc;
    outline: 0;
  }

  textarea {
    width: 80%;
    padding: 1rem;
    border: 1px solid #ccc;
    outline: 0;
  }

  button {
    padding: 0.7rem 1rem;
    height: 50%;
    background-color: var(--first-color);
    color: #fff;
    border-radius: 0.3rem;
    margin-left: 0.5rem;
  }

  @media screen and (max-width: 480px) {
    form {
      flex-direction: column;
      align-items: flex-start;

      textarea {
        width: 100%;
        margin-bottom: 0.5rem;
      }

      button {
        width: 100%;
      }
    }

    h4 {
      margin: 0 1rem;
    }
    input {
      width: 100%;
      padding: 0.7 rem;
    }
  }
`;

const PrevComment = styled.div`
  display: flex;
  background-color: #fff;
  width: 70%;
  align-items: center;
  justify-content: flex-start;
  margin: 1rem 4rem;
  padding: 0.4rem;

  img {
    width: 50px;
    border-radius: 50%;
  }

  span.dt {
    font-size: 0.8rem;
    color: #c0c1c2;
    padding-right: 3rem;
  }

  p.icn {
    font-size: 0.9rem;
    margin-top: 1rem;
    display: flex;
    margin-left: 1rem;

    & > * {
      margin-left: 1rem;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 480px) {
    width: 90%;
    margin: 1rem;
  }
`;

const SingleProject = () => {
  const location = useLocation();
  const projectId = location.pathname.split("/")[2];
  const [openModal, setOpenModal] = useState(false);
  const [cmmt, setCmmt] = useState("");
  const userId =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.id
      : false;
  const userType =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.isStaff
      : false;

  const url = "/project/" + projectId;
  const url1 = "/projectComment/all/" + projectId;

  const [cdata, setComments] = useState([]);
  const [project, setProject] = useState([]);

  useEffect(() => {
    const getNews = async () => {
      try {
        const res = await publicRequest.get(url);
        setProject(res.data);
        const comm = await userRequest.get(url1);
        setComments(comm.data);
      } catch (error) {
        console.log(error);
      }
    };
    getNews();
  }, [url, url1]);

  const handleClick = (e) => {
    e.preventDefault();
    publicRequest
      .post("/projectComment", { userId, projectId, comment: cmmt })
      .then((res) => {
        setCmmt("");
      });
  };

  return (
    <>
      {openModal && <Modal closeModal={setOpenModal} />}
      <Itter>
        <Container>
          <Section>
            <div className="col-md-4 col-12 mx-auto">
              <div className="card border-0 shadow-lg">
                <img
                  src={project.img}
                  alt={project.title}
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="single-project-info">
              <article className="desc">
                <h3>Description</h3>
                <p>{project.message}</p>
              </article>
              <article className="info">
                <h3>Details</h3>
                <p>
                  <span>Category : </span> {project.categroy}
                </p>
                <p>
                  <span>kilometres/Number : </span>
                  {project.kilometres}
                </p>
                <p>
                  <span>About : </span> {project.message}
                </p>
                <p>
                  <span>cost : </span> #
                  {project.cost &&
                    project.cost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                </p>
                <p>
                  <span>Location : </span> {project.lga}
                </p>
                <p>
                  <span>Supervising M.D.A : </span> {project.mda}
                </p>
                <p>
                  <span>Geo-Coordinates : </span> {project.longitude}-
                  {project.latitude}
                  <Link
                    to={`/maps/?lat=${project.latitude}&lng=${project.longitude}`}
                  >
                    Map
                  </Link>
                </p>
                {/* <p>
                Duration :
                {project.active ? `${project.progress}` : `${project.progress}`}
              </p> */}
                <p>{project.active && "Active"}</p>
              </article>
            </div>
          </Section>
        </Container>
        {userType && (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "2rem",
              }}
            >
              <p style={{ margin: 10 }}>Official Report</p>
              <button onClick={() => setOpenModal(!openModal)}>Report</button>
            </div>
          </>
        )}
        <Comment>
          <h4>
            {project.projectComments
              ? project.projectComments.length + " "
              : "No "}
            Comment
          </h4>
          <div>
            <form>
              <label htmlFor="comment">Comment:</label>
              {/* <textarea
            id="comment"
            placeholder="Comment"
            name="comment"
            rows="2"
          ></textarea> */}
              <input
                type="hidden"
                name="newsId"
                value={projectId}
                required
                readOnly
              />
              <input
                type="hidden"
                name="userId"
                value={projectId}
                required
                readOnly
              />
              {/* <input
              type="text"
              id="comment"
              placeholder="Comment"
              name="comment"
              value={cmmt}
              disabled={userId ? false : true}
              onChange={(e) => setCmmt(e.target.value)}
            /> */}
              <textarea
                name="comment"
                id="comment"
                maxLength="280"
                placeholder="Comment"
                value={cmmt}
                disabled={userId ? false : true}
                onChange={(e) => setCmmt(e.target.value)}
              ></textarea>
              {userId ? (
                <button onClick={handleClick} className="btn" primary="true">
                  Share
                </button>
              ) : (
                <button to={"/login"} className="btn" primary="true">
                  Login
                </button>
              )}
            </form>
          </div>
        </Comment>

        {cdata
          .reverse()
          .slice(0, 10)
          .map((comment, index) => (
            <PrevComment key={index}>
              <img src={require("../../images/staff.png")} alt="" />
              <div className="prevComments">
                <h4>
                  {comment.userId.name}{" "}
                  <span className="dt">
                    {moment(comment.createdAt).fromNow(true)}
                  </span>
                </h4>
                <p>{comment.comment}</p>
                {userId && (
                  <p className="icn">
                    <AiOutlineLike />
                    <AiOutlineDislike />
                    <Link>Reply</Link>
                  </p>
                )}
              </div>
            </PrevComment>
          ))}
      </Itter>
    </>
  );
};

export default SingleProject;
