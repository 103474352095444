import React, { useEffect } from "react";
import Footmark from "../components/footmark/Footmark";
import RegisterForm from "../components/register/Register";

function Register(props) {
  useEffect(() => {
    props.funcNav(false);
  }, [props]);
  return (
    <div>
      <RegisterForm />
      <Footmark />
    </div>
  );
}

export default Register;
