import { useNavigate, Link } from "react-router-dom";
import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login";
// import TwitterLogin from "react-twitter-login";
import logo from "../../images/new_logo.png";
import bgImage from "../../images/Meaningful-Community-Engagement-graphic.png";
import { BiArrowBack } from "react-icons/bi";
import { login, facebooLogin } from "../../redux/apiCalls";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background-image: url(${bgImage});
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 50%;

  p.backBtn {
    cursor: pointer;
    align-self: flex-start;
    padding: 2rem;
    font-size: 20px;
    font-weight: 500;
  }

  img {
    height: 150px;
    margin: 1.5rem 0;
  }

  p.header {
    text-align: center;
    font-size: 2rem;
  }

  h1 {
    font-weight: 400;
    font-size: 3rem;
    margin: 1rem auto;
  }

  form {
    width: 60%;
  }

  button {
    width: 100%;
    margin: 0.5rem 0;
  }

  button.btn {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0;
    border-radius: 0.3rem;
    background-color: var(--first-color);
  }
  button.cls {
    width: 100%;
    margin: 1rem 0;
    padding: 1rem 0;
    border-radius: 0.3rem;
    background-color: #ccc;
  }

  p.no-account {
    margin-bottom: 2rem;
  }

  .signup {
    color: var(--first-color);
    margin-left: 0.5rem;
    font-weight: 400;
  }

  div.socialAuth {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 900px) {
    width: 100%;

    form {
      width: 90%;
    }

    p.header {
      font-size: 1.5rem;
    }

    div.socialAuth {
      display: grid;
    }
  }
`;

const FormGroup = styled.div`
  width: 100%;

  label {
    display: block;
    margin: 1rem 0;
    color: var(--first-color);
    font-weight: 500;
    letter-spacing: 0.4rem;
    font-size: 1rem;
  }

  input {
    padding: 1.5rem 1rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    outline: none;
  }
`;

function LoginForm() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const reduxDispatch = useDispatch();
  const nav = useNavigate();

  // const authHandler = (err, data) => {
  //   console.log(err, data);
  // };

  const responseFacebook = (response) => {
    facebooLogin(reduxDispatch, {
      socialId: response.userID,
      name: response.name,
      email: response.email,
      img: response.picture.data.url,
      password: response.id,
    })
      .then(() => {
        nav("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.message);
      });
    // const addUs = async () => {
    //   try {
    //     const lg = await publicRequest
    //       .post("/auth/facebook", {
    //         socialId: response.userID,
    //         name: response.name,
    //         email: response.email,
    //         img: response.picture.data.url,
    //         password: response.id,
    //       })
    //       .then((res) => res.json);
    //     console.log(lg);
    //   } catch (error) {
    //     console.log(error.message);
    //   }
    // };
    // addUs();
    console.log({
      socialId: response.userID,
      name: response.name,
      email: response.email,
      img: response.picture.data.url,
      password: response.id,
    });
  };

  let fbContent;
  fbContent = (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_KEY}
      autoLoad={false}
      fields="name,email,picture"
      style={{
        backgroundColor: "#4267b2",
        color: "#fff",
        fontSize: "16px",
        padding: "12px 24px",
        border: "none",
        borderRadius: "10px",
      }}
      // onClick={componentClicked}
      callback={responseFacebook}
    />
  );

  // const twContent = (
  //   <TwitterLogin
  //     authCallback={authHandler}
  //     consumerKey={process.env.REACT_APP_TWITTER_KEY}
  //     consumerSecret={process.env.REACT_APP_TWITTER_SECRET}
  //   />
  // );

  const handleClick = (e) => {
    e.preventDefault();
    login(reduxDispatch, { email, password })
      .then(() => {
        nav("/");
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Container>
      <FormContainer>
        <p onClick={() => nav("/", { replace: true })} className="backBtn">
          <BiArrowBack size={20} />
          Back
        </p>

        <img src={logo} alt="" />
        <p className="header">ADAMAWA STATE TECHNOLOGICAL MONITORING HUB</p>
        <h1>Sign In</h1>
        <form>
          <FormGroup>
            <label htmlFor="email" className="form-control">
              Email
            </label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="password" className="form-control">
              Password
            </label>
            <input
              type="password"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </FormGroup>
          <button onClick={handleClick} className="btn btn-primary">
            Login
          </button>
          <Link to={"/"}>
            <button className="cls btn-primary">Cancel</button>
          </Link>
        </form>
        <p>--OR--</p>
        <div className="socialAuth">
          {fbContent}
          {/* &nbsp; {twContent} */}
        </div>
        <p className="no-account">
          Don't have account!
          <Link className="signup" to={"/register"}>
            SIGN UP NOW
          </Link>
        </p>
      </FormContainer>
    </Container>
  );
}

export default LoginForm;
