import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import Newsletter from "../components/newsletter/Newsletter";
import LocalPartners from "../components/partners/LocalPartners";

const PartnerProjects = () => {
  return (
    <>
      <LocalPartners />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default PartnerProjects;
