import React from "react";
import Footer from "../components/footer/Footer";
import Footmark from "../components/footmark/Footmark";
import ContactPage from "../components/contact/Contact";
import Newsletter from "../components/newsletter/Newsletter";
import Sponsors from "../components/sponsors/Sponsors";
import Accordion from "../components/accordion/Accordion";

const Contact = () => {
  return (
    <>
      <ContactPage />
      <Accordion />
      <Sponsors />
      <Newsletter />
      <Footer />
      <Footmark />
    </>
  );
};

export default Contact;
