import React from "react";

const LgaDashboard = () => {
  return (
    <div>
      <iframe
        title="Report Section"
        width="100%"
        height="900"
        src="https://app.powerbi.com/view?r=eyJrIjoiMmM2MTU3ODQtNWZkZC00OTMxLTgwNTQtYzM3ZGI5ZDM0MDEzIiwidCI6ImY2ZjcwZjFiLTJhMmQtNGYzMC04NTJhLTY0YjhjZTBjMTlkNyIsImMiOjF9"
        frameborder="0"
        allowFullScreen="true"
      ></iframe>
    </div>
  );
};

export default LgaDashboard;
