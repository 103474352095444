import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FaFacebookF, FaTwitter, FaUserCheck } from "react-icons/fa";
import { AiOutlineMail } from "react-icons/ai";
import { BsFillTelephoneFill } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/apiCalls";

const Header = styled.header`
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  padding: 0.6rem 2rem;
  border-bottom: 1px solid #eee;
  border-top: 3px solid var(--second-color);

  p {
    padding: 0 3px;
    font-size: var(--smaller-font-size);
    color: var(--text-color);
  }

  .usr {
    color: var(--fifth-color);
  }

  .icn {
    cursor: pointer;
    color: #000;
    margin: 0 2px;
    font-size: var(--normal-font-size);
  }

  p :hover,
  .icn :hover {
    color: var(--title-color);
  }

  @media screen and (max-width: 580px) {
    padding: 0.5rem 0.5rem;

    p {
      padding: 0 1px;
    }

    .icn {
      margin: 0 1px;
      font-size: var(--smaller-font-size);
    }

    .usr {
      display: none;
    }

    .forUser {
      display: none;
    }
  }
`;

const MenuBars = styled(FaUserCheck)`
  cursor: pointer;
  align-self: center;
  justify-self: center;
  margin-right: 0.4rem;
`;

const ModalDiv = styled.div`
  width: 100%;
  padding: 1rem;
  position: absolute;
  height: 500px;
  top: 100px;
  right: 150%;
  background-color: #fff;
  z-index: 100;

  ul {
    display: inline-block;

    li {
      color: var(--fourth-color);
      font-size: 2rem;
    }
  }
`;

const Barner = ({ isOpen, toggler }) => {
  const reduxDispatch = useDispatch();

  const user =
    JSON.parse(localStorage.getItem("persist:root")) &&
    JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
      .currentUser !== null
      ? JSON.parse(JSON.parse(localStorage.getItem("persist:root")).user)
          .currentUser.user.name
      : false;
  return (
    <>
      <Header>
        <p>
          <AiOutlineMail className="icn" />
          &nbsp; info@admehub.ng | &nbsp;&nbsp;
          <BsFillTelephoneFill className="icn" />
          &nbsp;07062259055, &nbsp;07045955257
        </p>
        <p>
          {user ? (
            <>
              <MenuBars onClick={toggler} />
              <span className="usr">{user ? user + " " : null}</span>
              <Link
                to="/"
                className="forUser"
                onClick={() => {
                  logout(reduxDispatch);
                  window.location.reload();
                }}
                style={{ textDecoration: "none" }}
              >
                Logout
              </Link>
            </>
          ) : null}
          <FaFacebookF className="icn" /> | <FaTwitter className="icn" />
        </p>
        <ModalDiv isOpen={isOpen} toggler={toggler}>
          <ul>
            <Link to={"/logout"}>
              <li>Logout</li>
            </Link>
          </ul>
        </ModalDiv>
      </Header>
    </>
  );
};

export default Barner;
