import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Recaptcha from "react-recaptcha";
import logo from "../../images/new_logo.png";
import bg from "../../images/Community-Engagement.jpg";
import { publicRequest } from "../../requestMethod";
import { Button } from "../Button";
import { BiArrowBack } from "react-icons/bi";

const Container = styled.div`
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

const ImgContainer = styled.div`
  display: flex;
  height: 100vh;
  width: 50%;

  @media screen and (max-width: 480px) {
    height: 200px;
    width: 100vw;

    img.bgImg {
      image-size: cover;
      image-position: top;
    }
  }
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 50%;
  min-height: 100vh;

  p.backBtn {
    cursor: pointer;
    align-self: flex-start;
    padding: 2rem;
    font-size: 20px;
    font-weight: 500;
  }

  img.logo {
    width: 120px;
    margin: 1rem 0;
  }

  p.header {
    text-align: center;
    font-size: 1.5rem;
  }

  h1 {
    font-weight: 400;
    font-size: 2rem;
    margin: 0.3rem auto;
  }
  .message {
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(155, 50%, 75%);
    margin: 0.5rem;
    background-color: hsl(125, 90%, 75%);
    font-weight: 500;
  }

  .axios_error {
    display: flex;
    color: #fff;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    border: 2px solid hsl(9, 98%, 51%);
    margin: 0.5rem;
    background-color: hsl(9, 82%, 55%);
    font-weight: 500;
  }

  form {
    width: 100%;
    padding: 0.5rem;
  }
  .btn-grp {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  input.btn {
    width: 70%;
    margin: 0.5rem 0;
    border: 0;
    padding: 1rem 3rem;
    border-radius: 0.3rem;
    background-color: var(--first-color);
    color: #fff;
  }

  button.cls {
    width: 100%;
    margin: 0.2rem 0;
    padding: 1rem 3rem;
    border-radius: 0.3rem;
    background-color: #ccc;
  }

  .agree {
    color: var(--fifth-color);
  }

  .chk {
    margin: 0.5rem 1rem;
  }

  .terms {
    font-weight: 500;
    color: #000;
    margin-left: 0.3rem;
  }

  .signup {
    color: var(--first-color);
    margin-left: 0.5rem;
    font-weight: 400;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
    padding: 1rem 0;
    display: grid;

    img.logo {
      margin: 0.5rem auto;
    }

    form {
      width: 90%;
    }

    button {
      width: 80%;
    }

    button.cls {
      width: 70%;
    }

    p.no-account {
      text-align: center;
      padding-bottom: 2rem;
    }

    p.header {
      font-size: 1rem;
    }
  }
`;

const FormGroup = styled.div`
  width: 45%;
  margin: 0.5rem 1rem;
  float: left;

  label {
    display: block;
    margin: 0.5rem 0 0.2rem 0;
    color: var(--first-color);
    font-weight: 500;
    letter-spacing: 0.4rem;
    font-size: 1rem;
  }

  input {
    padding: 0.8rem;
    background-color: #fcfcfc;
    border: 0;
    border-bottom: 1px solid #ccc;
    width: 100%;
    outline: none;
  }

  .err {
    border-bottom: 2px solid hsl(14, 92%, 76%);
  }

  .crt {
    border-bottom: 2px solid hsl(124, 62%, 76%);
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
`;

function RegisterForm() {
  const url = "/auth/register";
  const nav = useNavigate();

  const [data, setData] = useState({
    name: "",
    username: "",
    email: "",
    phone: "",
    agree: false,
    password: "",
    confirmPassword: "",
  });
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  // specifying your onload callback function
  var callback = function () {
    console.log("Done!!!!");
  };

  // specifying verify callback function
  var verifyCallback = function (response) {
    if (response) {
      setIsVerified(true);
      // console.log(response);
    }
  };

  function handleSubmit(e) {
    e.preventDefault();

    setFormErrors(validate(data));
    setIsSubmit(true);
  }

  function handleChange(e) {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  }

  function handleSelect(e) {
    const newData = { ...data };
    newData[e.target.name] = !data.agree;
    setData(newData);
  }

  useEffect(() => {
    if (
      Object.keys(formErrors).length === 0 &&
      isSubmit &&
      data.name !== "" &&
      isVerified
    ) {
      publicRequest.post(url, data).then((res) => {
        setData({
          name: "",
          username: "",
          email: "",
          phone: "",
          agree: false,
          password: "",
          confirmPassword: "",
        });
      });
    }
  }, [formErrors, isSubmit, data, isVerified]);

  const validate = (values) => {
    const errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!values.name) {
      errors.name = "Name is required!";
    }
    if (!values.email) {
      errors.email = "Email is required!";
    } else if (values.email && !regex.test(values.email)) {
      errors.email = "This is not a valid email format!";
    }
    if (!values.phone) {
      errors.phone = "phone number is required!";
    } else if (values.phone.length < 10) {
      errors.phone = "phone number must be more than 10 digits";
    } else if (values.phone.length > 14) {
      errors.phone = "phone cannot exceed more than 14 digits";
    }
    if (!values.password) {
      errors.password = "password is required!";
    } else if (values.password.length < 5) {
      errors.password = "password must be more than 5 characters";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "confirm password is required!";
    }
    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = "confirm password must match password";
    }
    return errors;
  };

  return (
    <Container>
      <ImgContainer>
        <img className="bgImg" src={bg} alt="" />
      </ImgContainer>
      <FormContainer>
        <p onClick={() => nav("/", { replace: true })} className="backBtn">
          <BiArrowBack size={20} />
          Back
        </p>
        <img className="logo" src={logo} alt="" />
        <p className="header">
          ADAMAWA STATE TECHNOLOGICAL MONITORING AND EVALUATION HUB
        </p>
        <h1>Sign Up</h1>
        {Object.keys(formErrors).length === 0 && isSubmit && isVerified ? (
          <div className="ui message success">
            Registration was successfully, thank you for signing up with us.
          </div>
        ) : (
          ""
        )}
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup>
            <label htmlFor="name" className="form-control">
              Name
            </label>
            <input
              type="text"
              placeholder="Enter your Fullname"
              className={formErrors.name ? "err" : isSubmit ? "crt" : undefined}
              name="name"
              onChange={(e) => handleChange(e)}
              value={data.name}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="username" className="form-control">
              Username
            </label>
            <input
              type="text"
              placeholder="Enter your Username"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.username ? "err" : isSubmit ? "crt" : undefined
              }
              name="username"
              value={data.username}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="email" className="form-control">
              Email
            </label>
            <input
              type="text"
              placeholder="Enter your email"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.email ? "err" : isSubmit ? "crt" : undefined
              }
              name="email"
              value={data.email}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="phone" className="form-control">
              Phone Number
            </label>
            <input
              type="text"
              placeholder="Enter your phone number"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.phone ? "err" : isSubmit ? "crt" : undefined
              }
              name="phone"
              value={data.phone}
              required
            />
          </FormGroup>
          {/* <FormGroup>
            <label htmlFor="lga" className="form-control">
              L.G.A
            </label>
            <input
              type="text"
              placeholder="Enter your local gov't area"
              onChange={(e) => handleChange(e)}
              className={formErrors.lga ? "err" : isSubmit ? "crt" : undefined}
              value={data.lga}
              name="lga"
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="address" className="form-control">
              Residential Address
            </label>
            <input
              type="text"
              placeholder="Enter your residential address"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.address ? "err" : isSubmit ? "crt" : undefined
              }
              value={data.address}
              name="address"
              required
            />
          </FormGroup> */}
          <FormGroup>
            <label htmlFor="password" className="form-control">
              Password
            </label>
            <input
              type="password"
              placeholder="Enter your password"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.password ? "err" : isSubmit ? "crt" : undefined
              }
              name="password"
              value={data.password}
              required
            />
          </FormGroup>
          <FormGroup>
            <label htmlFor="confirmPassword" className="form-control">
              Repeat Password
            </label>
            <input
              type="password"
              placeholder="Enter your password again"
              name="confirmPassword"
              onChange={(e) => handleChange(e)}
              className={
                formErrors.confirmPassword
                  ? "err"
                  : isSubmit
                  ? "crt"
                  : undefined
              }
              value={data.confirmPassword}
              required
            />
            <i>{formErrors.name}</i>
          </FormGroup>
          <label className="agree">
            <input
              className="chk"
              checked={data.agree}
              name="agree"
              type="checkbox"
              onChange={(e) => handleSelect(e)}
              required
            />
            I agree to the
            <Link className="terms" to={"/login"}>
              Terms of User
            </Link>
          </label>
          <Recaptcha
            sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_KEY}
            render="explicit"
            onloadCallback={callback}
            verifyCallback={verifyCallback}
          />
          <div className="btn-grp">
            <input type="submit" className="btn btn-primary" value="Register" />
          </div>
        </form>
        <div className="btn-grp">
          <Button to={"/"} secondary={"true"} className="btn btn-primary">
            Cancel
          </Button>
        </div>
        <p className="no-account">
          Already have an account!
          <Link className="signup" to={"/login"}>
            SIGN IN
          </Link>
        </p>
      </FormContainer>
    </Container>
  );
}

export default RegisterForm;
